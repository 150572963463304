









import { Component, Vue } from 'vue-property-decorator';
import Notification from '@/components/Ui/Notification.vue';

@Component({
  components: {
    Notification
  },
})
export default class UiLayout extends Vue {}
