import {EnvProvider} from '@/utilities/EnvProvider';
import {getCountry, getLang} from '@/plugins/vue-i18n';
import {LocaleService} from '@/utilities/locale';

export const getFileUrl = (url: string): string => {
  return url
    ? `${LocaleService.replaceDomain(EnvProvider('URL')) + url}`
    : '';
};

export const getSiteUrl = (pageUrl: string) => {
  return pageUrl
    ? `${EnvProvider('URL_WEBSITE')}/${getCountry()}/${getLang()}/${pageUrl}`
    : '';
};
