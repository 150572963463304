














import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Rating extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private title!: string;

  @Prop({
    type: String,
    required: false
  })
  private subtitle!: string;

  private get attrs() {
    return {
      ...this.$attrs,
      'background-color': 'primary lighten-4',
      'empty-icon': '$emptyStar',
      'full-icon': '$fullStar',
      hover: true,
      length: 5,
      size: this.$store.getters.isMobileAdaptiveMode ? '50' : '64',
      ripple: false,
    };
  }
}
