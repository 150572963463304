






























import { Component, Vue, Prop } from 'vue-property-decorator';

import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/core';

import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
SwiperClass.use([Pagination, Navigation]);
Vue.use(getAwesomeSwiper(SwiperClass));

import 'swiper/swiper-bundle.css';

export interface IBreakpointSetting {
  slidesPerView?: number;
  spaceBetween?: number;
}

export interface IBreakpoint {
  [key: number]: IBreakpointSetting;
}

@Component({
  inheritAttrs: false
})
export default class Carousel extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  private allowPagination!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private allowArrows!: boolean;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private type!: string;

  @Prop({
    type: Array,
    required: true
  })
  private itemsList!: [];

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  private paginationIsClickable!: boolean;

  @Prop({
    type: Object,
    required: false,
    default: () => ({
      1024: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 25
      },
      320: {
        slidesPerView: 1,
      }
    })
  })
  private breakpoints!: IBreakpoint;

  private swiperOptions = {
    ...((this.allowPagination) && { pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: this.paginationIsClickable,
        dynamicBullets: true,
        dynamicMainBullets: 4
      }}),
    loop: this.$attrs.loop ?? false,
    breakpoints: this.breakpoints,
  };

  private get classObject(): object {
    return {
      [`${this.type}`]: !!this.type,
    };
  }

  private created() {
    this.$parent.$on('nextSlide', () => {
      if (this.$refs.swiper) {
        // @ts-ignore
        this.$refs.swiper.$swiper.slideNext();
      }
    });
  }
}
