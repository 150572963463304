














import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class NewPolicy extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private icon!: string;

  @Prop({
    type: String,
    required: true
  })
  private label!: string;
}
