import {sortList, sortType} from '@/interfaces';

export enum sortDirectionEnum {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum sortFieldEnum {
  updatedAt = 'updatedAt',
  date = 'date',
  amount = 'amount',
  deposit = 'deposit',
  createdAt = 'createdAt',
}

export interface ISortQuery {
  sortField: sortFieldEnum;
  sortDirection: sortDirectionEnum;
}

export const sortDictionary = {
  [sortList.updatedDesc]: { sortField: 'updatedAt', sortDirection: 'DESC'},
  [sortList.updatedAsc]: { sortField: 'updatedAt', sortDirection: 'ASC'},
  [sortList.dateDesc]: { sortField: 'date', sortDirection: 'DESC'},
  [sortList.dateAsc]: { sortField: 'date', sortDirection: 'ASC'},
  [sortList.amountDesc]: { sortField: 'amount', sortDirection: 'DESC'},
  [sortList.amountAsc]: { sortField: 'amount', sortDirection: 'ASC'},
  [sortList.createdAtDesc]: { sortField: sortFieldEnum.createdAt, sortDirection: sortDirectionEnum.DESC},
  [sortList.createdAtAsc]: { sortField: sortFieldEnum.createdAt, sortDirection: sortDirectionEnum.ASC},
};

export const getSortOptions = (sortType: sortType) => sortDictionary[sortType];
