import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';

export type DictionaryNameType
  = 'countries' | 'damaged-places' | 'claim-types' | 'damage-types';

export interface ICommonDictionary {
  name: string;
  values: ICommonDictionaryValues;
}

export interface ICommonDictionaryValues {
  [key: string]: {
    [key: string]: string;
  };
}

export interface IDictionaryService {
  getDictionary: (dictionary: DictionaryNameType) => Promise<ICommonDictionary>;
}

const getDictionary = (dictionary: DictionaryNameType): Promise<ICommonDictionary> => api
  .get(`dictionary/${dictionary}`)
  .then((response: AxiosResponse<ICommonDictionary>) => response.data)
  .catch((error) => Promise.reject(error));

const DictionaryService: IDictionaryService = {
  getDictionary
};

export default DictionaryService;
