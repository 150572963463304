import {ActionTree} from 'vuex';
import { RootState } from '@/store';
import NotificationService from '@/services/api/notification.servise';
import {INotification} from '@/interfaces';
import {IPolicyState} from '@/store/modules/policy.store';
import {cloneObject} from '@/utilities';

export interface INotificationState {
  notifications: INotification[];
  notificationsPage: number;
  notificationsCount: number;
}

const initialState = (): INotificationState => ({
  notifications: [],
  notificationsPage: 1,
  notificationsCount: 0,
});

const state = initialState();

const getters = {
  getNotifications: (state: INotificationState) => state.notifications,
  getNotificationsPage: (state: INotificationState) => state.notificationsPage,
  getNotificationsCount: (state: INotificationState) => state.notificationsCount,
};

const mutations = {
  ['SET_NOTIFICATION'](state: INotificationState, payload: INotification) {
    state.notifications = [
      payload,
      ...cloneObject(state.notifications),
    ];
  },
  ['SET_NOTIFICATIONS_PAGINATION'](state: INotificationState, payload: INotification[]) {
    state.notifications = [
      ...cloneObject(state.notifications),
      ...payload,
    ];
  },
  ['SET_NOTIFICATIONS_PAGE'](state: INotificationState, payload: number) {
    state.notificationsPage = payload;
  },
  ['SET_NOTIFICATIONS_COUNT'](state: INotificationState, payload: number) {
    state.notificationsCount = payload;
  },
};

const actions: ActionTree<IPolicyState, RootState> = {
  getNotifications: ({commit}, page: number = 1) => {
    commit('SET_UNIFORM_LOADING', {uploadNotifications: true});
    return NotificationService.getNotifications(page)
      .then((data) => {
        if (data) {
          commit('SET_NOTIFICATIONS_PAGINATION', data.elements);
          commit('SET_NOTIFICATIONS_PAGE', data.currentPage);
          commit('SET_NOTIFICATIONS_COUNT', data.totalResult);
          commit('SET_UNIFORM_LOADING', {skeletonLoader: false});
        }
      })
      .finally(() => commit('SET_UNIFORM_LOADING', {uploadNotifications: false}));
  },
  streamNotifications: ({commit, getters}) => {
    getters.getStream.addEventListener('NOTIFICATION', (message) => {
      if (message.data) {
        commit('SET_NOTIFICATION', JSON.parse(message.data).notificationResponse);
        commit('SET_NOTIFICATIONS_COUNT', JSON.parse(message.data).totalResult);
      }
    });
  },
  closeNotification: ({commit}, payload: string) => {
    return NotificationService.closeNotification(payload)
      .then((response) => {
        commit('SET_NOTIFICATIONS_COUNT', response.totalResult);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
