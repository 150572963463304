import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import { MediaExtensionType, SenderRoleType } from '@/interfaces';

export interface IChatCount {
  count: number;
}

export interface IChatResponse {
  currentPage: number;
  totalResult: number;
  pageSize: number;
  sortField: string;
  sortDirection: string;
}

export interface IChatContact {
  address: string|null;
  chatId: string|null;
  name: string|null;
  initials: string|null;
  content: string|null;
  dateTime: Date|string|null;
  messageId: string|null;
  notReadMessages: number|null;
  sender: SenderRoleType|null;
}

export interface IChatContacts extends IChatResponse {
  elements: IChatContact[];
}

export interface IChatSuggestedUser {
  name: string;
  initials: string;
  address: string;
  propertyId: string;
  tenantId: string;
}

export interface IChatSuggestedUsers extends IChatResponse {
  elements: IChatSuggestedUser[];
}

export interface IChatMessages extends IChatResponse {
  elements: IChatGroupMessage[];
}

export interface IChatGroupMessage {
  date: Date|string;
  messages: IChatMessage[];
}

export interface IChatMessage {
  chatId: string|null;
  messageId: string|null;
  sender: SenderRoleType|null;
  content: string|null;
  documents: IChatMessageDocument[]|null;
  notReadMessages: number|null;
  time: string|null;
}

export interface IChatMessageDocument {
  id: string;
  fileName: string;
  fileType: MediaExtensionType;
  url: string;
  size: string;
  createdAt: string|null;
}

export interface IChatSuggestedUserParameters {
  search?: string;
  page?: number;
  pageSize?: number;
}

export interface IChatContactParameters {
  page?: number;
  pageSize?: number;
}

export interface IChatService {
  getChatContactList: (queryParams: string) => Promise<IChatContacts>;
  getChatContactById: (id: string) => Promise<IChatContact>;
  getChatSuggestedUserList: (queryParams: string) => Promise<IChatSuggestedUsers>;
  setChatStart: (data: { propertyId: string, policyId: string }) => Promise<IChatContact>;
  getChatMessages: (query: string) => Promise<IChatMessages>;
  uploadChatFile: (chatId: string, formData, onUploadProgress) => Promise<IChatMessageDocument>;
  chatCount: () => Promise<IChatCount>;
}

const getChatContactList = (queryParams: string): Promise<IChatContacts> => api
  .get(`chat?${queryParams}`)
  .then((response: AxiosResponse<IChatContacts>) => response.data)
  .catch((error) => Promise.reject(error));

const getChatContactById = (id: string): Promise<IChatContact> => api
  .get(`chat/${id}`)
  .then((response: AxiosResponse<IChatContact>) => response.data)
  .catch((error) => Promise.reject(error));

const getChatSuggestedUserList = (queryParams: string): Promise<IChatSuggestedUsers> => api
  .get(`chat/suggested-user?${queryParams}`)
  .then((response: AxiosResponse<IChatSuggestedUsers>) => response.data)
  .catch((error) => Promise.reject(error));

const setChatStart = (data: { propertyId: string, policyId: string }): Promise<IChatContact> => api
  .post(`chat/tenant/start?${data.propertyId ? `propertyId=${data.propertyId}` : `policyId=${data.policyId}`}`)
  .then((response: AxiosResponse<IChatContact>) => response.data)
  .catch((error) => Promise.reject(error));

const getChatMessages = (query: string): Promise<IChatMessages> => api
  .get(`chat/messages?${query}`)
  .then((response: AxiosResponse<IChatMessages>) => response.data)
  .catch((error) => Promise.reject(error));

const uploadChatFile = (chatId: string, formData, onUploadProgress): Promise<IChatMessageDocument> => api
  .post(
    `chat/${chatId}/document`,
  formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress
    }
  )
  .then((response: AxiosResponse<IChatMessageDocument>) => response.data)
  .catch((error) => Promise.reject(error));

const chatCount = (): Promise<IChatCount> => api
  .get('chat/unread-count')
  .then((response: AxiosResponse<IChatCount>) => response.data)
  .catch((error) => Promise.reject(error));

const ChatService: IChatService = {
  getChatContactList,
  getChatContactById,
  getChatSuggestedUserList,
  setChatStart,
  getChatMessages,
  uploadChatFile,
  chatCount,
};

export default ChatService;
