import store from '@/store';

export const LocaleService = {
  getCountryFromDomain: (hostname: string = window.location.hostname): string => {
    const domainArr = hostname.split('.');
    return domainArr.length > 1
      ? domainArr[domainArr.length - 1] === 'com' ? 'de' : domainArr[domainArr.length - 1]
      : 'de';
  },
  getSeparatedByCode: (localeCode: string) => {
      const locale = localeCode.toLocaleLowerCase().split('-');
      return {lang: locale[0], country: locale[1]};
  },
  getLocaleCode: (lang?: string, country?: string): string | undefined => {
      return !!lang || !!country
        ? `${lang?.toLocaleLowerCase()}-${country?.toLocaleUpperCase()}`
        : undefined;
  },
  replaceDomain: (url, country = store.getters.country): string => {
    return url.replace('.com', `.${country}`);
  }
};
