




import { Component, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { layoutDictionary } from '@/dictionaries/layout.dictionary';
import { ILayout } from '@/interfaces';
import { debounce } from '@/utilities';
import { AdaptiveMode } from '@/store/modules/ui.store';

@Component
export default class App extends Vue {
  public resizeDebounceTimeout = 25;

  public created() {
    this.resizeHandler();
  }

  public mounted() {
    window.addEventListener(
      'resize',
      debounce(this.resizeHandler, this.resizeDebounceTimeout),
      { passive: true },
    );
  }

  public beforeDestroy() {
    window.removeEventListener(
      'resize',
      this.resizeHandler,
    );
  }

  public get layout(): VueConstructor | null {
    return this.$route.name
      ? layoutDictionary.filter((item: ILayout) => {
          return item.name === (this.$route.meta!.layout || 'default');
        })[0].component
      : null;
  }

  public resizeHandler() {
    const viewPort = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    this.$store.commit('SET_VIEW_PORT', viewPort);

    if (viewPort < 650) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.XS) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.XS);
      }
    } else if (viewPort < 1000) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.SM) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.SM);
      }
    } else {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.MD) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.MD);
      }
    }
  }
}
