import axios, {AxiosResponse} from 'axios';
import {api} from '@/services/api/api.service';

export interface ILocaleApiService {
  getLocaleSetting: () => Promise<{settings: ILocaleSettings[]}>;
  getPredictLocale: () => Promise<{lang: string}>;
}

export interface ILocaleSettings {
  country: string;
  enabled: boolean;
  languages: string[];
}
const getLocaleSetting = (): Promise<{settings: ILocaleSettings[]}> => api
  .get('/settings')
  .then((response: AxiosResponse<{settings: ILocaleSettings[]}>) => response.data)
  .catch((error) => Promise.reject(error));

const getPredictLocale = (): Promise<{lang: string}> => axios.get('/lang')
  .then((response: AxiosResponse<{lang: string}>) => response.data)
  .catch((error) => Promise.reject(error));

const LocalApiService: ILocaleApiService = {
  getLocaleSetting,
  getPredictLocale,
};

export default LocalApiService;
