



















import {Component, Prop, Vue} from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';
import {scrollTo} from '@/utilities/scroll';

const { mapFields } = createHelpers({
  getterType: 'getUiFields',
  mutationType: 'updateUiFields',
});

@Component({
  computed: {
    ...mapFields([
      'isNotificationActive'
    ])
  }
})
export default class HeaderAlert extends Vue {
  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  private notificationCount!: number;

  private isNotificationActive!: boolean;

  private openNotifications() {
    this.isNotificationActive = !this.isNotificationActive;
    scrollTo('.header');
  }
}
