import Vue from 'vue';
import VueRouter, {
  RawLocation,
  Route,
  RouteConfig
} from 'vue-router';
import {scrollTo} from '@/utilities/scroll';
import multiguard from 'vue-router-multiguard';
import {guardsList} from '@/router/guards';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:lang/sign-in',
    name: 'signIn',
    component: () => import(
      /* webpackChunkName: "SingInPage" */
      '@/pages/SignIn/SignIn.page.vue'
      ),
  },
  {
    path: '/:lang',
    name: 'home',
    component: () => import(
      /* webpackChunkName: "HomePage" */
      '@/pages/Home/Home.page.vue'
    ),
  },
  {
    path: '/:lang/profile',
    name: 'profile',
    component: () => import(
      /* webpackChunkName: "ProfilePage" */
      '@/pages/Profile/Profile.page.vue'
    ),
  },
  {
    path: '/:lang/intro',
    name: 'intro',
    component: () => import(
      /* webpackChunkName: "IntroPage" */
      '@/pages/Intro/Intro.page.vue'
    ),
  },
  {
    path: '/:lang/policy/:id',
    name: 'policy',
    component: () => import(
      /* webpackChunkName: "PolicyPage" */
      '@/pages/Policy/Policy.page.vue'
      ),
  },
  {
    path: '/:lang/cancel-policy/:id',
    name: 'cancel-policy',
    component: () => import(
      /* webpackChunkName: "PolicyCancelPage" */
      '@/pages/Policy/Policy.cancellation.page.vue'
      ),
  },
  {
    path: '/:lang/invoices/:id',
    name: 'invoices',
    component: () => import(
      /* webpackChunkName: "PolicyCancelPage" */
      '@/pages/Policy/Policy.invoices.page.vue'
      ),
  },
  {
    path: '/:lang/claim',
    name: 'claim',
    component: () => import(
      /* webpackChunkName: "ClaimPage" */
      '@/pages/Claim/Claim.page.vue'
      ),
  },
  {
    path: '/:lang/claim-details/:id',
    name: 'claim-details',
    component: () => import(
      /* webpackChunkName: "ClaimDetailsPage" */
      '@/pages/Claim/Claim.details.page.vue'
      ),
  },
  {
    path: '/:lang/claim-explain/:id',
    name: 'claim-explain',
    component: () => import(
      /* webpackChunkName: "ClaimExplainPage" */
      '@/pages/Claim/Claim.explain.page.vue'
      ),
  },
  {
    path: '/:lang/ui',
    name: 'ui',
    meta: { layout: 'ui' },
    component: () => import(
      /* webpackChunkName: "UIPage" */
      '@/pages/Ui/Ui.page.vue'
    ),
  },
  {
    path: '*',
    redirect: (to) => {
      return {
        name: to.path === '/'
          ? 'home'
          : '404',
      };
    }
  },
  {
    path: '/:lang/404',
    name: '404',
    component: () => import(
      /* webpackChunkName: "Error400" */
      '@/pages/Errors/Error.404.vue'
    ),
  },
  {
    path: '/500',
    name: '500',
    component: () => import(
      /* webpackChunkName: "Error500" */
      '@/pages/Errors/Error.500.vue'
    ),
  },
  {
    path: '/:lang/maintenance',
    name: 'maintenance',
    component: () => import(
      /* webpackChunkName: "ErrorMaintenance" */
      '@/pages/Errors/Error.maintenance.vue'
    ),
  },
  {
    path: '/:lang/help',
    name: 'help',
    component: () => import(
      /* webpackChunkName: "HelpPage" */
      '@/pages/Help/Help.page.vue'
    ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (from, to) => {
    from.path === to.path
      ? scrollTo('header', 'smooth')
      : scrollTo('header');
  }
});

router.beforeEach(multiguard(guardsList));

// Handle navigation duplication for router push (Globally)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      resolve(this.currentRoute);
    }, (error) => {
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

export default router;
