












import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  private visible!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '580'
  })
  private modalWidth!: string;
}
