












import {Component, Vue} from 'vue-property-decorator';
import {EnvProvider} from '@/utilities';

@Component
export default class Footer extends Vue {
  private companyName: string = EnvProvider('TITLE');

  private get copyrightText(): string {
    return ' &#169; 2021. ' + this.$t('footer.allRights');
  }
}
