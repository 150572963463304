<template lang="pug">
  v-btn(
    v-bind="$attrs"
    icon
    link
    plain
    :ripple="false"
    color="primary"
    data-test-id="table-btn"
    @click="$emit('click')"
  )
    slot
</template>

<script>
  import {Component, Vue} from 'vue-property-decorator';

  @Component
  export default class TableBtn extends Vue {}
</script>
