






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Link extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private title!: string;
}
