

















import {Component, Prop, Vue} from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';

const {mapFields} = createHelpers({
  getterType: 'getUiFields',
  mutationType: 'updateUiFields',
});

@Component({
  computed: {
    ...mapFields([
      'isTableSettingsActive',
      'isTableSortActive',
      'isTableFilterActive'
    ])
  }
})
export default class TableToolbar extends Vue {
  @Prop({type: Boolean, required: false})
  private isSortBy!: false;
}
