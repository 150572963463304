import {ActionTree} from 'vuex';
import store, { RootState } from '@/store';
import DictionaryService, {DictionaryNameType, ICommonDictionary} from '@/services/api/dictionary.service';
import {IDictionaryObject} from '@/interfaces';

const getDictionaryArray = (dictionaryState) => {
  return Object.keys(dictionaryState).reduce((types: IDictionaryObject[], key: string) => {
    types.push({
      name: key,
      value: dictionaryState[key][`${store.getters.language}`]
    });
    return types;
  }, []);
};

export interface IDictionaryState {
  dictionaries: ICommonDictionary[];
}

const initialState = (): IDictionaryState => ({
  dictionaries: [],
});
const state = initialState();

const getters = {
  getDictionary: (state: IDictionaryState) => {
    return (name: DictionaryNameType) => {
      const result: ICommonDictionary | undefined =
        state.dictionaries.find((dictionary: ICommonDictionary) => dictionary.name === name);
      return result ? result.values : {};
    };
  },
  getDictionaryArray: (state: IDictionaryState) => {
    return (name: DictionaryNameType) => {
      const result: ICommonDictionary | undefined =
        state.dictionaries.find((dictionary: ICommonDictionary) => dictionary.name === name);
      return result ? getDictionaryArray(result.values) : [];
    };
  },
  customSelectLabel: (state: IDictionaryState, getters) => {
    return (value: string, name: DictionaryNameType) => {
      return Object.keys(getters.getDictionary( name )).length
        ? getters.getDictionary( name )[value]
          ? getters.getDictionary( name )[value][store.getters.language]
          : getters.getDictionary( name )[value]
        : '';
    };
  }
};

const mutations = {
  ['SET_DICTIONARY'](state: IDictionaryState, payload: ICommonDictionary) {
    if (!state.dictionaries.some((item) => item.name === payload.name)) {
      state.dictionaries.push(payload);
    }
  },
};

const actions: ActionTree<IDictionaryState, RootState> = {
  getDictionary: ({commit}, dictionary: DictionaryNameType) => {
    if (!state.dictionaries.some((item) => item.name === dictionary)) {
      commit('SET_UNIFORM_LOADING', {[dictionary]: true});
      return DictionaryService.getDictionary(dictionary)
        .then((data: ICommonDictionary) => commit('SET_DICTIONARY', data))
        .finally(() => commit('SET_UNIFORM_LOADING', {[dictionary]: false}));
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
