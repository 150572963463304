























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class Select extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: []
  })
  public items!: [];
}
