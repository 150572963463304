














import {Vue, Component, Prop} from 'vue-property-decorator';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { LocaleService } from '@/utilities/locale';

@Component
export default class InputTelephone extends Vue {
  private inputInstance;
  private localErrorMessage: string = '';

  private get getClass() {
    return {
      'no-border': this.$attrs.transparent,
      'disabled': this.$attrs.disabled,
    };
  }

  @Prop({
    type: String,
    required: true,
  })
  private id!: string;

  @Prop({
    type: Boolean,
    required: false,
  })
  private required!: false;

  @Prop({
    type: Object,
    required: false,
  })
  private value!: {
    dialCode: string;
    number: string;
  };

  private countryCode = LocaleService.getCountryFromDomain();

  private mounted() {
    const input = document.querySelector(`#${this.id}`);
    this.inputInstance = intlTelInput(input as Element, {
      utilsScript: require('intl-tel-input/build/js/utils.js'),
      separateDialCode: true,
      initialCountry: this.countryCode
    });

    if (!this.value.dialCode) {
      this.value.dialCode = '+' + this.inputInstance.getSelectedCountryData().dialCode;
    }

    if (this.value.number && this.value.dialCode) {
      this.inputInstance.setNumber(this.value.dialCode + this.value.number);
    }

    input!.addEventListener('countrychange', () => {
      this.phoneNumber(this.value.number, this.inputInstance.getSelectedCountryData().dialCode);
    });
  }

  private phoneNumber(event, dialCode = this.value.dialCode ?? this.inputInstance.getSelectedCountryData().dialCode) {
    if (event?.includes('+' + this.clearDialCode(dialCode))) {
      this.$emit('input', {
        dialCode: '+' + this.clearDialCode(dialCode),
        number: event.split('+' + this.clearDialCode(dialCode))[1]
      });
    } else {
      this.$emit('input', {
        dialCode: '+' + this.clearDialCode(dialCode),
        number: event
      });
    }

    if ((!this.inputInstance?.getValidationError() && event?.length > 4) || !event?.length) {
      this.localErrorMessage = '';
    } else {
      this.addError('invalid');
    }
  }

  private clearDialCode(dialCode) {
    return dialCode.replace('+', '');
  }

  private get attrs() {
    return {
      ...this.$attrs,
      name: 'phone',
      type: 'text',
      id: this.id,
      value: this.value.number,
      'error-messages': this.$attrs['error-messages'] || this.localErrorMessage
    };
  }

  private get inputDialCode() {
    return this.inputInstance.getSelectedCountryData();
  }

  private get actions() {
    return {
      input: this.phoneNumber,
      blur: () => {
        return (this.required && this.value.number && this.value.number.length === 0)
          ? this.addError('required')
          : this.phoneNumber(this.value.number, this.value.dialCode);
      },
    };
  }

  private addError(messageType: string) {
    this.localErrorMessage = this.$tc(`validation.${messageType}`);
  }
}
