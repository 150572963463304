

















import { Component, Vue } from 'vue-property-decorator';
import LogOut from '@/components/Modals/LogOut.modal.vue';
import DocumentsUploadModal from '@/components/Modals/DocumentsUpload.modal.vue';

@Component({
  components: {
    LogOut,
    DocumentsUploadModal
  }
})
export default class Modals extends Vue {
  private visibleList: boolean[] = [];
  private modalList = [
    {
      id: 'logOut',
      title: 'logOut.modal.title',
      component: LogOut
    },
    {
      id: 'documentsUpload',
      title: 'documentsUpload.modal.title',
      component: DocumentsUploadModal
    }
  ];

  private created(): void {
    this.$root.$on(
      'open-modal',
      (id) => this.openModal(id)
    );
    this.$root.$on(
      'close-modal',
      this.closeModal
    );
  }

  private setDefaultVisibleList() {
    this.visibleList = [...Array(this.modalList.length).fill(false)];
  }

  private getIndexById(id: string): number {
    return this.modalList
      .findIndex((modal) => modal.id === id);
  }

  private openModal(id: string): void {
   this.setDefaultVisibleList();
   this.visibleList
    .splice(this.getIndexById(id), 1, true);
  }

  private closeModal(): void {
    this.setDefaultVisibleList();
  }
}
