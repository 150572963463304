import {ActionTree} from 'vuex';
import store, { RootState } from '@/store';
import SearchService from '@/services/api/search.service';
import {ISuggestionsPlaces, uniformLoadingEnum} from '@/interfaces';
import {ISuggestionAddressChecked, ISuggestionCheck} from '@/services/api/policy.service';

export const getSuggestedSession = () => {
  return store.getters.getSuggestSession
    ? {suggestSession: store.getters.getSuggestSession}
    : {};
};

export interface ISearchRequest {
  place: string;
  limitCountries: boolean;
  suggestSession: string;
}

export interface ISearchState {
  suggestSession: string;
  suggestionChecked: null | ISuggestionAddressChecked;
}

const initialState = (): ISearchState => ({
  suggestSession: '',
  suggestionChecked: null,
});
const state = initialState();

const getters = {
  getSuggestSession: (state: ISearchState) => state.suggestSession,
  getSuggestionChecked: (state: ISearchState) => state.suggestionChecked,
};

const mutations = {
  ['SET_SEARCH_SESSION'](state: ISearchState, payload: string) {
    state.suggestSession = payload;
  },
  ['SET_SUGGESTION_CHECKED'](state: ISearchState, payload: ISuggestionAddressChecked) {
    state.suggestionChecked = payload;
  }
};

const actions: ActionTree<ISearchState, RootState> = {
  getSuggestions: async ({commit, state}, payload: ISearchRequest) => {
    return await SearchService.getNewAddresses({...payload, ...getSuggestedSession()})
      .then((response: ISuggestionsPlaces) => {
        if (!state.suggestSession.length) {
          commit('SET_SEARCH_SESSION', response.session);
        }
        return response;
      })
      .catch((error) => Promise.reject(error));
  },
  checkSuggestion: ({commit}, payload: ISuggestionCheck) => {
    commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.checkSuggestion]: true });
    return SearchService.suggestionCheck(payload)
      .then((response: ISuggestionAddressChecked) => {
        if (response) {
          commit('SET_SUGGESTION_CHECKED', response);
        }
        return response;
      })
      .finally(() =>
        commit('SET_UNIFORM_LOADING', { [uniformLoadingEnum.checkSuggestion]: false }));
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
