
























import {Vue, Component, Prop} from 'vue-property-decorator';
import ErrorIcon from '@/assets/icons/error-icon.svg';

@Component({
  inheritAttrs: false,
  components: {
    ErrorIcon
  }
})
export default class ProgressBarRatio extends Vue {
  @Prop({
    type: Number,
    required: true,
    default: 500
  })
  public coverage!: number;

  @Prop({
    type: Number,
    required: true,
    default: 500
  })
  public charged!: number;

  @Prop({
    type: String,
    required: true,
    default: 'EUR'
  })
  public currency!: string;

  private get getBarsWidths(): object {
    return {
      '--coverageWidth': `${this.getCoverageWidth}%`,
      '--chargedWidth': `${this.getChargedWidth}%`
    };
  }

  private get getCoverageWidth() {
    return this.coverage === 0
    ? 100
    : Math.ceil(this.coverage * 100 / (this.charged + this.coverage));
  }

  private get getChargedWidth() {
    return Math.ceil(this.charged * 100 / (this.charged + this.coverage));
  }

  private get getClasses() {
    return {
      'no-charged': this.getChargedWidth < 1,
      'small-coverage': this.getCoverageWidth < 30,
      'main-color-blue': this.$attrs.mainColor === 'blue'
    };
  }

  private formatCurrency(amount, currency = 'EUR') {
    return this.$options.filters?.[currency](amount) ?? amount;
  }
}
