













import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import Modals from '@/components/Modals/Modals.vue';
import Header from '@/components/Layout/Header/Header.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';
import Notification from '@/components/Ui/Notification.vue';

@Component({
  metaInfo: { titleTemplate: '%s - Garentii' },
  components: {
    Notification,
    Header,
    Footer,
    Loading,
    Modals,
  },
})
export default class DefaultLayout extends Vue {
  private get isLayoutNeeded() {
    return this.$route.name !== 'signIn' && this.$store.getters.getUserFields('isAuthenticated');
  }

  private get isFooterPermitted() {
    const isChatPage = this.$route.name === 'chat';
    const { isMobileAdaptiveMode } = this.$store.getters;

    return isChatPage && isMobileAdaptiveMode
      ? false
      : this.isLayoutNeeded;
  }
}
