import Vue from 'vue';
import Vuex from 'vuex';
import ui, {IUiState} from '@/store/modules/ui.store';
import user, {IUserState} from '@/store/modules/user.store';
import policy, {IPolicyState} from '@/store/modules/policy.store';
import claim, {IClaimState} from '@/store/modules/claim.store';
import notification, {INotificationState} from '@/store/modules/notification.store';
import chat, {IChatState} from '@/store/modules/chat.store';
import error, {IValidationState} from '@/store/modules/error.store';
import dictionary, {IDictionaryState} from '@/store/modules/dictionary.store';
import search, {ISearchState} from '@/store/modules/search.store';
import locale, {ILocaleStore} from '@/store/modules/locale.store';

Vue.use(Vuex);

export interface RootState {
  ui: IUiState;
  user: IUserState;
  policy: IPolicyState;
  claim: IClaimState;
  notification: INotificationState;
  chat: IChatState;
  errors: IValidationState;
  dictionary: IDictionaryState;
  search: ISearchState;
  locale: ILocaleStore;
}

export default new Vuex.Store<RootState>({
  modules: {
    ui,
    user,
    policy,
    claim,
    notification,
    chat,
    error,
    dictionary,
    search,
    locale,
  },
});
