










import { Vue, Component } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class Input extends Vue {
  public get getClass() {
    return {
      'transparent': this.$attrs.transparent
    };
  }
}
