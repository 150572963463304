import axios, { AxiosResponse } from 'axios';
import requestInterceptor from '@/services/api/interceptors/request.interceptor';
import errorInterceptor from '@/services/api/interceptors/error.interceptor';
import cancelInterceptor from '@/services/api/interceptors/cancel.interceptor';
import { EnvProvider } from '@/utilities';
import languageInterceptor from '@/services/api/interceptors/language.interceptor';

axios.defaults.headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Accept': 'application/json',
};

export const api = axios.create({
  baseURL: '/api/' + EnvProvider('API_VERSION'),
});

api.interceptors
  .request.use(requestInterceptor);
api.interceptors
  .request.use(cancelInterceptor);
api.interceptors
  .request.use(languageInterceptor);
api.interceptors
  .response.use((response: AxiosResponse) => response, errorInterceptor);
