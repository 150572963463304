







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PulseLoader extends Vue {
  @Prop({
    type: String,
    required: true
  })
  public loader!: string;

  public get visible() {
    return this.$store.getters.isUniformLoading(this.loader);
  }
}
