










































import {Component, Vue} from 'vue-property-decorator';
import HomeIcon from '@/assets/icons/navigation/home_icon.svg';
import CenterIcon from '@/assets/icons/navigation/resolution_center.svg';
import ProfileIcon from '@/assets/icons/navigation/user_icon.svg';
import HelpIcon from '@/assets/icons/navigation/help_icon.svg';
import LogoutIcon from '@/assets/icons/navigation/logout_icon.svg';
import PropertyIcon from '@/assets/icons/navigation/properties_icon.svg';
import PlusIcon from '@/assets/icons/navigation/plus-round_icon.svg';
import LogOut from '@/components/Modals/LogOut.modal.vue';

@Component({
  components: {
    HomeIcon,
    CenterIcon,
    ProfileIcon,
    HelpIcon,
    LogoutIcon,
    PropertyIcon,
    PlusIcon,
    LogOut
  }
})
export default class Navigation extends Vue {
  private logOutModal = false;

  private navigationList = [
    {
      title: 'home',
      routeName: 'home',
      icon: HomeIcon
    },
    {
      title: 'resolution-center',
      routeName: 'claim',
      icon: CenterIcon
    },
    {
      title: 'profile',
      routeName: 'profile',
      icon: ProfileIcon
    },
    {
      title: 'help',
      routeName: 'help',
      icon: HelpIcon
    }
  ];

  private logOut() {
    this.$store.dispatch('logOut');
  }

  private mounted() {
    this.$store.dispatch('streamChatCount');
  }
}
