import {ActionTree} from 'vuex';
import store, { RootState } from '@/store';
import PolicyApiService, {
  IAddress,
  IAddressManual,
  ISuggestionAddress,
  ISuggestionAddressChecked
} from '@/services/api/policy.service';
import {
  IPolicyFullItem,
  IPolicyInvoice,
  IPolicyItem,
  IRemoveFile,
  IUploadedFileData, UploadOriginEnum,
  UploadTypeEnum,
} from '@/interfaces';
import { IUploadFile } from '@/components/Ui/Uploader/interfaces';

export interface IPolicyState {
  policies: null | IPolicyItem[];
  policyUploadedFileData: IUploadedFileData[];
  invoices: IPolicyInvoice[];
  policyItemInfo: Partial<IPolicyFullItem>;
  policyInvoicesCount: number;
}

export const defaultAddressManual = (): IAddressManual => ({
  street: null,
  streetNumber: null,
  zip: null,
  city: null,
  country: null,
});

export const defaultSuggestionAddress = (): ISuggestionAddress => ({
  placeId: null,
  title: null,
  highlightedTitle: null,
});

export const defaultSuggestionChecked = (): ISuggestionAddressChecked => ({
  suggestion: defaultSuggestionAddress(),
  streetNumber:	null,
  zip: null
});

export const defaultAddress = (): IAddress => ({
  address: defaultAddressManual(),
  fullAddress: '',
  suggestionAddress: null,
});

const initialState = (): IPolicyState => ({
  policies: null,
  policyUploadedFileData: [],
  invoices: [],
  policyItemInfo: {
    address: {},
    lastInvoice: {},
    rentContract: {},
    inventoryProtocol: {},
    attachments: []
  },
  policyInvoicesCount: 0,
});
const state = initialState();

const getters = {
  getPolicies: (state: IPolicyState) => state.policies || [],
  getPolicyUploadedFiles: (state: IPolicyState) => state.policyUploadedFileData,
  getPolicyItemInfo: (state: IPolicyState) => state.policyItemInfo,
  getPolicyInvoices: (state: IPolicyState) => state.invoices,
  getPolicyInvoicesCount: (state: IPolicyState) => state.policyInvoicesCount,
};

const mutations = {
  ['SET_POLICIES'](state: IPolicyState, payload) {
    state.policies = payload;
  },
  ['SET_POLICY_UPLOADED_FILE'](state: IPolicyState, payload: IUploadedFileData) {
    state.policyUploadedFileData.push(payload);
  },
  ['SET_POLICY_RENT'](state: IPolicyState, payload: IUploadedFileData) {
    (state.policyItemInfo as IPolicyFullItem).rentContract = payload;
  },
  ['SET_POLICY_INVENTORY_PROTOCOL'](state: IPolicyState, payload: IUploadedFileData) {
    (state.policyItemInfo as IPolicyFullItem).inventoryProtocol = payload;
  },
  ['REMOVE_POLICY_UPLOAD_FILE'](state: IPolicyState, payload: IRemoveFile) {
    state.policyUploadedFileData = state.policyUploadedFileData.filter((item) => item.id !== payload.documentId);
  },
  ['REMOVE_POLICY_UPLOADED_FILES'](state: IPolicyState) {
    state.policyUploadedFileData = [];
  },
  ['SET_POLICY_INFO'](state: IPolicyState, payload: IPolicyFullItem) {
    state.policyItemInfo = payload;
  },
  ['SET_POLICY_INVOICES'](state: IPolicyState, payload: IPolicyInvoice[]) {
    state.invoices = payload;
  },
  ['SET_POLICY_INVOICES_COUNT'](state: IPolicyState, payload: number) {
    state.policyInvoicesCount = payload;
  },
  ['REMOVE_POLICY_ATTACHMENT_BY_ID'](state: IPolicyState, payload: IUploadFile[]) {
    state.policyItemInfo.attachments = payload;
  },
};

const actions: ActionTree<IPolicyState, RootState> = {
  getUserPolicies: ({commit}) => {
    return PolicyApiService.getPolicies()
      .then((policies) => {
        if (policies?.elements) {
          commit('SET_POLICIES', policies.elements);
        } else {
          commit('SET_POLICIES', []);
        }
      });
  },
  setNewPolicyFile: ({commit}, payload) => {
    commit('SET_LOADING', {uploadFile: true});
    return PolicyApiService.uploadFile(payload)
      .then((data: IUploadedFileData) => {
        commit('SET_POLICY_UPLOADED_FILE', data);
      })
      .finally(() => commit('SET_LOADING', {uploadFile: false}));
  },
  removePolicyFile: ({commit}, payload: IRemoveFile) => {
    commit('SET_LOADING', {removeFile: true});
    return PolicyApiService.removeFile(payload)
      .then(() => {
        commit('REMOVE_POLICY_UPLOAD_FILE', payload);
      })
      .finally(() => commit('SET_LOADING', {removeFile: false}));
  },
  getPolicyInfo: ({commit}, id: string) => {
    commit('SET_LOADING', {policy: true});
    return PolicyApiService.getPolicyInfo(id)
      .then((answer) => commit('SET_POLICY_INFO', answer))
      .finally(() => commit('SET_LOADING', {policy: false}));
  },
  uploadPolicyFile: ({commit}, formData: FormData) => {
    commit('SET_LOADING', {file: true});
    return PolicyApiService.uploadPolicyFile(formData)
      .then((answer) => {
        formData.get('type') === UploadTypeEnum.RENT_CONTRACT
          ? commit('SET_POLICY_RENT', answer)
          : commit('SET_POLICY_INVENTORY_PROTOCOL', answer);
      })
      .finally(() => commit('SET_LOADING', {file: false}));
  },

  cancelPolicy: ({commit}, payload) => {
    commit('SET_LOADING', {cancel: true});
    const data = {
      data: {
        reason: payload.data.reason,
        address: payload.isManual ? payload.data.address.address : null,
        addressId: payload.data.address.suggestionAddress?.placeId,
        suggestionSession: store.state.search.suggestSession,
        otherReason: payload.data.otherReason
      },
      id: payload.id
    };
    return PolicyApiService.cancelPolicy(data)
      .finally(() => commit('SET_LOADING', {cancel: false}));
  },
  getPolicyInvoices: ({commit}, data) => {
    commit( 'SET_UNIFORM_LOADING', {invoices: true});
    return PolicyApiService.getPolicyInvoices(data)
      .then((answer) => {
        commit('SET_POLICY_INVOICES', answer!.elements);
        commit('SET_POLICY_INVOICES_COUNT', answer.totalResult);
      })
      .finally(() => commit('SET_UNIFORM_LOADING', {invoices: false}));
  },
  clearPolicyFiles: ({commit}) => {
    commit('REMOVE_POLICY_UPLOADED_FILES');
  },
  uploadPolicyAttachments: (_, {file, id}: {file: File, id: string}) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    formData.append('origin', UploadOriginEnum.POLICY);
    // type is needed if origin === policy
    formData.append('type', UploadTypeEnum.TENANT_ATTACHMENTS);

    return PolicyApiService.uploadPolicyFile(formData);
  },
  removePolicyAttachments: ({dispatch}, payload: {documentId: string, id: string}) => {
    return PolicyApiService.removeFile(payload)
      .then(() => dispatch('removePolicyAttachmentById', payload.documentId));
  },
  removePolicyAttachmentById: ({commit, getters}, documentId: string) => {
    const attachments = getters.getPolicyItemInfo.attachments.filter((item) => item.id !== documentId);
    commit('REMOVE_POLICY_ATTACHMENT_BY_ID', attachments);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
