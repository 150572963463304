import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {
  IClaimDetails,
  IExplainClaimData,
  IPagination,
  IRemoveFile,
  IResponsePageableArray,
  IUploadedFileData
} from '@/interfaces';

export interface IRemovedInfo {
  status: number;
  message: string;
}

export interface IClaimApiService {
  uploadFile: (formData: FormData) => Promise<IUploadedFileData>;
  removeFile: (data: IRemoveFile) => Promise<IRemovedInfo>;
  getClaims: (data: IPagination) => Promise<IResponsePageableArray>;
  getClaim: (id: string) => Promise<IClaimDetails>;
  acceptClaim: (id: string) => Promise<IClaimDetails>;
  explainClaim: (data: IExplainClaimData) => Promise<IClaimDetails>;
}

const uploadFile = (formData: FormData): Promise<IUploadedFileData> => api
  .post(`claim/${formData.get('id')}/document`, formData)
  .then((response: AxiosResponse<IUploadedFileData>) => response.data)
  .catch((error) => Promise.reject(error));

const removeFile = (data: IRemoveFile): Promise<IRemovedInfo> => api
  .delete(`claim/${data.id}/document/${data.documentId}`)
  .then((response: AxiosResponse<IRemovedInfo>) => response.data)
  .catch((error) => Promise.reject(error));

const getClaims = (params): Promise<IResponsePageableArray> => api
  .get('claim', {params})
  .then((response: AxiosResponse<IResponsePageableArray>) => response.data)
  .catch((error) => Promise.reject(error));

const getClaim = (id: string): Promise<IClaimDetails> => api
  .get(`claim/${id}`)
  .then((response: AxiosResponse<IClaimDetails>) => response.data)
  .catch((error) => Promise.reject(error));

const acceptClaim = (id: string): Promise<IClaimDetails> => api
  .patch(`claim/${id}/accept`, id)
  .then((response: AxiosResponse<IClaimDetails>) => response.data)
  .catch((error) => Promise.reject(error));

const explainClaim = (data: IExplainClaimData): Promise<IClaimDetails> => api
  .patch(`claim/${data.claimId}/update`, {justificationMessage: data.justificationMessage})
  .then((response: AxiosResponse<IClaimDetails>) => response.data)
  .catch((error) => Promise.reject(error));

const ClaimApiService: IClaimApiService = {
  uploadFile,
  removeFile,
  getClaims,
  getClaim,
  acceptClaim,
  explainClaim,
};

export default ClaimApiService;
