import TokenService from '@/services/data/token.service';
import EventSource from 'eventsource';
import {EnvProvider} from '@/utilities';

export interface ISseEvents {
  eventsStream: EventSource;
}

export const eventsStream = () => {
  return new EventSource(`${window.location.origin}/api/${EnvProvider('API_VERSION')}/events`, {
    headers: {
      'Authorization': 'Bearer ' + TokenService.getToken(),
      'X-Accel-Buffering': 'no',
      'Accept': 'text/event-stream',
      'Accept-Language': localStorage.getItem('locale'),
      'Cache-Control': 'no-cache',
    }
  });
};

const sseEvents: ISseEvents = {
  eventsStream
};

export default sseEvents;
