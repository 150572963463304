import {ActionTree} from 'vuex';
import {RootState} from '@/store';
import LocalApiService, {ILocaleSettings} from '@/services/api/locale.service';
import {LocaleService} from '@/utilities/locale';

export interface ILocaleStore {
  localeSettings: null | ILocaleSettings[];
  currentSettings: null | ILocaleSettings;
  supportedLangs: null | string[];
  predictLang: null | string;
}

const initialState = (): ILocaleStore => ({
  localeSettings: null,
  currentSettings: null,
  supportedLangs: null,
  predictLang: null,
});

const state = initialState();

const getters = {
  getLocaleSettings: (state: ILocaleStore) => state.localeSettings,
  getCurrentSettings: (state: ILocaleStore) => state.currentSettings,
  getSupportedLangs: (state: ILocaleStore) => state.supportedLangs,
  getPredictLang: (state: ILocaleStore) => state.predictLang,
};

const mutations = {
  ['SET_SETTINGS'](state: ILocaleStore, payload: ILocaleSettings[]) {
    state.localeSettings = payload;
  },
  ['SET_CURRENT_SETTINGS'](state: ILocaleStore, payload: ILocaleSettings) {
    state.currentSettings = payload;
  },
  ['SET_SUPPORTED_LANGS'](state: ILocaleStore, payload: ILocaleSettings) {
    const lang: string[] = payload.languages.map((item: string) => LocaleService.getLocaleCode(item, payload.country)!);
    state.supportedLangs = [...lang, ...payload.languages];
  },
  ['SET_PREDICT_LANG'](state: ILocaleStore, payload: string) {
    state.predictLang = payload;
  },
};

const actions: ActionTree<ILocaleStore, RootState> = {
  localeSettings: ({ commit }) => {
    commit('SET_LOADING', {'locale-settings': true});
    return LocalApiService.getLocaleSetting()
      .then((response: {settings: ILocaleSettings[]}) => {
        if (!response?.settings) {
          throw new Error('Failed to receive locale settings!');
        }
        commit('SET_SETTINGS', response.settings);

        const currentCountry = response.settings.find((item) => item.country === LocaleService.getCountryFromDomain());
        commit('SET_CURRENT_SETTINGS', currentCountry);
        commit('SET_SUPPORTED_LANGS', currentCountry);
      })
      .finally(() => commit('SET_LOADING', {'locale-settings': false}));
  },
  predictLocale: ({commit}) => {
    commit('SET_LOADING', {'predict-lang': true});
    return LocalApiService.getPredictLocale()
      .then((response) => {
        commit('SET_PREDICT_LANG', response.lang);
        return response?.lang;
      })
      .finally(() => commit('SET_LOADING', {'predict-lang': false}));
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
