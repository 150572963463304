import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {IResponsePageableArray} from '@/interfaces';

export interface INotificationService {
  closeNotification: (id: string) => Promise<IResponsePageableArray>;
  getNotifications: (page: number) => Promise<IResponsePageableArray>;
}

const closeNotification = (id: string): Promise<IResponsePageableArray> => api
  .post(`notification/${id}/close`)
  .then((response: AxiosResponse<IResponsePageableArray>) => response.data)
  .catch((error) => Promise.reject(error));

const getNotifications = (page): Promise<IResponsePageableArray> => api
  .get(`notification?sortField=createdAt&sortDirection=desc&unread=true&page=${page}`)
  .then((response: AxiosResponse<IResponsePageableArray>) => response?.data)
  .catch((error) => Promise.reject(error));

const NotificationService: INotificationService = {
  closeNotification,
  getNotifications
};

export default NotificationService;
