
































import {Component, Prop, Vue} from 'vue-property-decorator';
import {getFullDate, getTime} from '@/utilities';
import {
  getNotificationIcon,
  NotificationTypePath
} from '@/dictionaries/notifications.dictionary';
import {NotificationType} from '@/interfaces';

@Component
export default class HeaderNotificationItem extends Vue {
  public getNotificationIcon = getNotificationIcon;
  public NotificationTypePath = NotificationTypePath;

  @Prop({type: Object, required: true})
  private item!: {
    id: string;
    title: string;
    content: string;
    createdAt: Date;
    type: NotificationType;
    contextId: string;
  };

  private removeNotification(id: string) {
    this.$emit('close-notification', id);
    this.$store.dispatch('closeNotification', id);
  }

  private getDate(date: Date): string {
    return ((new Date()).toDateString() === (new Date(date)).toDateString())
      ? getTime(date)
      : getFullDate(date);
  }

  private routerLink() {
    if ((this.item.type in NotificationTypePath) && NotificationTypePath[this.item.type]) {
      NotificationTypePath[this.item.type](this.item.contextId);
    }
  }
}
