import {IIcons} from '@/interfaces';

import CheckboxIcon from '@/assets/icons/checkbox.svg';
import CheckboxCheckedIcon from '@/assets/icons/checkbox_checked.svg';
import RadioIcon from '@/assets/icons/radio_button.svg';
import RadioCheckedIcon from '@/assets/icons/radio_button_checked.svg';
import CalendarIcon from '@/assets/icons/calendar_icon.svg';
import ChevronRight from '@/assets/icons/chevron_right.svg';
import ChevronLeft from '@/assets/icons/chevron_left.svg';
import ArrowForward from '@/assets/icons/arrow_forward.svg';
import TableMoreIcon from '@/assets/icons/table_more.svg';
import DeleteAttachment from '@/assets/icons/delete.svg';
import DocumentPlaceholderIcon from '@/assets/icons/document_placeholder.svg';
import NewPolicy from '@/assets/icons/new_policy.svg';
import AlertIcon from '@/assets/icons/alert.svg';
import CloseIcon from '@/assets/icons/close_icon.svg';
import Chat from '@/assets/icons/chat.svg';
import Property from '@/assets/icons/navigation/properties_icon.svg';
import FullStar from '@/assets/icons/full_star.svg';
import EmptyStar from '@/assets/icons/empty_star.svg';
import MenuBtn from '@/assets/icons/menu_icon.svg';
import CloseNotification from '@/assets/icons/close.svg';
import Policy from '@/assets/icons/policy_icon.svg';
import ClaimIcon from '@/assets/icons/claim.svg';
import MissingDocument from '@/assets/icons/missing_document.svg';
import Account from '@/assets/icons/navigation/user_icon.svg';
import NewMessage from '@/assets/icons/new_message.svg';
import InfoBtn from '@/assets/icons/info_btn.svg';
import InfoIcon from '@/assets/icons/info.svg';
import HelpIcon from '@/assets/icons/navigation/help_icon.svg';
import AddPropertyIcon from '@/assets/icons/add_property.svg';
import BackArrow from '@/assets/icons/arrow_backward.svg';
import SortIcon from '@/assets/icons/sort.svg';
import PlusIcon from '@/assets/icons/plus.svg';
import EyeIcon from '@/assets/icons/eye.svg';
import CloseEyeIcon from '@/assets/icons/eye_close.svg';

export const ICONS: IIcons = {
  checkbox: {
    component: CheckboxIcon,
  },
  checkboxChecked: {
    component: CheckboxCheckedIcon,
  },
  radio: {
    component: RadioIcon,
  },
  radioChecked: {
    component: RadioCheckedIcon,
  },
  calendar: {
    component: CalendarIcon,
  },
  chevronRight: {
    component: ChevronRight
  },
  chevronLeft: {
    component: ChevronLeft
  },
  arrowForward: {
    component: ArrowForward
  },
  tableMore: {
    component: TableMoreIcon
  },
  deleteAttachment: {
    component: DeleteAttachment
  },
  documentPlaceholder: {
    component: DocumentPlaceholderIcon
  },
  newPolicy: {
    component: NewPolicy
  },
  alert: {
    component: AlertIcon
  },
  close: {
    component: CloseIcon
  },
  chat: {
    component: Chat
  },
  property: {
    component: Property
  },
  fullStar: {
    component: FullStar
  },
  emptyStar: {
    component: EmptyStar
  },
  menu: {
    component: MenuBtn
  },
  closeNotification: {
    component: CloseNotification
  },
  policy: {
    component: Policy
  },
  claimIcon: {
    component: ClaimIcon
  },
  missingDocument: {
    component: MissingDocument
  },
  account: {
    component: Account
  },
  newMessage: {
    component: NewMessage
  },
  infoBtn: {
    component: InfoBtn
  },
  infoIcon: {
    component: InfoIcon
  },
  helpIcon: {
    component: HelpIcon
  },
  addPropertyIcon: {
    component: AddPropertyIcon
  },
  backArrow: {
    component: BackArrow
  },
  sortIcon: {
    component: SortIcon
  },
  plusIcon: {
    component: PlusIcon
  },
  eyeIcon: {
    component: EyeIcon
  },
  closeEyeIcon: {
    component: CloseEyeIcon
  },
};
