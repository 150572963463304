import { AxiosError } from 'axios';
import router from '@/router';
import store from '@/store';
import VueRouter from 'vue-router';

const errorInterceptor = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    if (router.currentRoute.name === 'signIn') {
      store.commit('SET_SIGN_IN_ERROR_DATA', error.response.data);
    } else {
      store.dispatch('logOut');
      return Promise.reject(error);
    }
  } else if (error.response && error.response.status === 400) {
    store.commit('SET_ERROR_DATA', error.response.data);
    return Promise.reject(error);
  } else if (error.response && error.response.status === 403) {
    if (router.currentRoute.name === 'signIn') {
      store.commit('SET_SIGN_IN_ERROR_DATA', error.response.data);
    }
    return Promise.reject(error);
  } else if (error.response && error.response.status === 404) {
    store.commit('SET_ERROR_DATA', error.response.data);
    // @ts-ignore
    if (router.history.current.name !== 'signIn') {
      router.push({
        name: '404',
        params: {
          errorCode: String(error.response.status),
          data: error.response.data
        }
      })
      .catch(() => {/* fixed navigation error */});
    } else {
      store.commit('SET_SIGN_IN_ERROR_DATA', error.response.data);
    }
    return Promise.reject(error);
  } else if (error.response && error.response.status === 409) {
    console.warn(error);
    return;
  } else if (error.response && (error.response.status >= 500 && error.response.status <= 599)) {
    router.push({ name: '500' });
    return Promise.reject(error);
  } else if (error.response && error.response.status) {
    router.push({
      name: '500',
      params: {
        errorCode: String(error.response.status)
      }
    })
    .catch((e) => {
      if (!VueRouter.isNavigationFailure(e, VueRouter.NavigationFailureType.redirected)) {
        Promise.reject(e);
      }
    });
    return Promise.reject(error);
  }
};

export default errorInterceptor;
