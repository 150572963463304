import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {RootState} from '@/store';
import {getField, updateField} from 'vuex-map-fields';
import ChatService, {
  IChatContact,
  IChatContactParameters,
  IChatContacts,
  IChatCount,
  IChatGroupMessage,
  IChatMessage,
  IChatMessages,
  IChatSuggestedUser,
  IChatSuggestedUserParameters,
  IChatSuggestedUsers,
} from '@/services/api/chat.service';
import TokenService from '@/services/data/token.service';
import Stomp, {Client} from 'webstomp-client';
import SockJS from 'sockjs-client';
import {cloneObject, EnvProvider, isToday} from '@/utilities';
import {IChatUploadDictionary, IDictionary, SenderRoleType} from '@/interfaces';

export interface IDefaultChatDictionary {
  page: number;
  size: number;
  total: number;
}

export interface IDefaultContactDictionary {
  page: number;
  size: number;
  total: number;
}

export interface IDefaultSuggestedUserDictionary {
  search: string;
  page: number;
  size: number;
  total: number;
}

export const defaultChatDictionary: IDefaultChatDictionary = {
  page: 1,
  size: 30,
  total: 1,
};

export const defaultContactDictionary: IDefaultContactDictionary = {
  page: 1,
  size: 20,
  total: 1,
};

export const defaultSuggestedUserDictionary: IDefaultSuggestedUserDictionary = {
  search: '',
  page: 1,
  size: 20,
  total: 1,
};

const isTodayAvailableInReceivedMessages = (receivedMessages) => {
  return !!(receivedMessages.length && receivedMessages
    .filter(
      (item: IChatGroupMessage) => isToday(new Date(item.date))
    ).length
  );
};

const generateLastGroupMessage = (receivedMessages: IChatGroupMessage[]) => {
  const isNewGroup = !isTodayAvailableInReceivedMessages(receivedMessages);
  const group: IChatGroupMessage = isNewGroup
    ? {
      date: new Date(),
      messages: []
    }
    : cloneObject(receivedMessages).pop() as IChatGroupMessage;

  return {
    isNewGroup,
    ...group,
  };
};

const generateUploadMessage = (
  payload: {
    file: File,
    messageId: string,
    chatId: string,
    sender: string,
    time: Date
  }
) => {
  const {file, messageId, chatId, sender, time} = payload;
  return {
    chatId,
    sender,
    messageId,
    time,
    documents: [
      {
        id: null,
        fileName: file.name,
        fileType: file.type,
        url: null,
        size: file.size,
        createdAt: null
      }
    ],
  };
};

const mergedContactList = (
  sender: IChatContact,
  recipient: IChatContact,
  message: IChatMessage,
  contactListWithoutRecipient: IChatContact[]
) => {
  const content = message.content
    ? message.content
    : message.documents && message.documents.length
      ? message.documents[0].fileName
      : '';

  return [
    ...(
      sender
        ? [{
          ...sender,
          ...(
            sender.chatId === (recipient && recipient.chatId)
              ? {
                content,
                dateTime: message.time,
              }
              : {}
          ),
          notReadMessages: 0
        }]
        : []
    ),
    ...(
      !sender || sender.chatId !== (recipient && recipient.chatId)
        ? [{
          ...recipient,
          content,
          dateTime: message.time,
          notReadMessages: message.notReadMessages
        }]
        : []
    ),
    ...(
      sender
        ? contactListWithoutRecipient.filter((item: IChatContact) => sender.chatId !== item.chatId)
        : contactListWithoutRecipient
    )
  ];
};

const stringifyQueryParameters = (parameters: IDictionary<string|number>) => {
  return encodeURI(
    Object.keys(parameters).map((key: string) => `${key}=${parameters[key]}`).join('&')
  );
};

const sortReceivedMessagesByDate = (messages) => {
  return messages.length
    ? messages
      .sort((a: IChatGroupMessage, b: IChatGroupMessage) => {
        return new Date(a.date).valueOf() - new Date(b.date).valueOf();
      })
      .map((group) => ({
          ...group,
          messages: group.messages.sort((a: IChatMessage, b: IChatMessage) => {
            return new Date(a.time as string).valueOf() - new Date(b.time as string).valueOf();
          })
      }))
    : [];
};

export interface IChatState {
  currentContact: IChatContact|null;
  stompClient: Client|null;
  receivedMessages: IChatGroupMessage[];

  chatPage: number;
  chatPageSize: number;
  chatTotalPage: number;

  contactList: IChatContact[];
  contactPage: number;
  contactPageSize: number;
  contactTotal: number;

  suggestedUserList: IChatSuggestedUser[];
  suggestedUserSearch: string;
  suggestedUserPage: number;
  suggestedUserPageSize: number;
  suggestedUserTotal: number;

  chatUploadDictionary: IChatUploadDictionary;

  chatCount: number;
  newMessageChatId: null | string;
}

const initialState = (): IChatState => ({
  currentContact: null,
  stompClient: null,
  receivedMessages: [],

  chatPage: defaultChatDictionary.page,
  chatPageSize: defaultChatDictionary.size,
  chatTotalPage: defaultChatDictionary.total,

  contactList: [],
  contactPage: defaultContactDictionary.page,
  contactPageSize: defaultContactDictionary.size,
  contactTotal: defaultContactDictionary.total,

  suggestedUserList: [],
  suggestedUserSearch: defaultSuggestedUserDictionary.search,
  suggestedUserPage: defaultSuggestedUserDictionary.page,
  suggestedUserPageSize: defaultSuggestedUserDictionary.size,
  suggestedUserTotal: defaultSuggestedUserDictionary.total,

  chatUploadDictionary: {},

  chatCount: 0,
  newMessageChatId: null,
});
const state = initialState();

const getters: GetterTree<IChatState, RootState> = {
  getChatField: (state: IChatState) => getField(state),
  contactList: (state: IChatState) => state.contactList ? state.contactList : [],
  suggestedUserList: (state: IChatState) => state.suggestedUserList ? state.suggestedUserList : [],
  currentContact: (state: IChatState) => state.currentContact ? state.currentContact : null,
  receivedMessages: (state: IChatState) => state.receivedMessages,
  getChatCount: (state: IChatState) => state.chatCount,
  chatPage: (state: IChatState) => state.chatPage,
  chatPageSize: (state: IChatState) => state.chatPageSize,
  chatTotalPage: (state: IChatState) => state.chatTotalPage,
  isOwnMessage: (state: IChatState, getters) => (sender: SenderRoleType) => {
    return sender === getters.getUserInfo.role;
  },
  chatUploadDictionary: (state: IChatState) => {
    return (messageId: string) => {
      return state.chatUploadDictionary
        ? state.chatUploadDictionary[messageId]
        : null;
    };
  },
  getNewMessageChatId: (state: IChatState) => state.newMessageChatId
};

const mutations: MutationTree<IChatState> = {
  updateChatField(state: IChatState, field: string) {
    return updateField(state, field);
  },

  ['SET_CONTACT_LIST'](state: IChatState, payload: IChatContact[]) {
    state.contactList = payload;
  },
  ['MERGE_CONTACT_LIST'](state: IChatState, payload: IChatContact[]) {
    state.contactList = [
      ...cloneObject(state.contactList),
      ...payload
    ];
  },
  ['RESET_CONTACT_LIST'](state: IChatState) {
    state.contactList = [];
  },
  ['SET_CONTACT_PAGE'](state: IChatState, payload: number) {
    state.contactPage = payload;
  },
  ['RESET_CONTACT_PAGE'](state: IChatState) {
    state.contactPage = defaultContactDictionary.page;
  },
  ['SET_CONTACT_PAGE_SIZE'](state: IChatState, payload: number) {
    state.contactPageSize = payload;
  },
  ['SET_CONTACT_TOTAL'](state: IChatState, payload: number) {
    state.contactTotal = payload;
  },

  ['SET_SUGGESTED_USER_LIST'](state: IChatState, payload: IChatSuggestedUser[]) {
    state.suggestedUserList = [
      ...cloneObject(state.suggestedUserList),
      ...payload
    ];
  },
  ['RESET_SUGGESTED_USER_LIST'](state: IChatState) {
    state.suggestedUserList = [];
  },
  ['SET_SUGGESTED_USER_SEARCH'](state: IChatState, payload: string) {
    state.suggestedUserSearch = payload;
  },
  ['SET_SUGGESTED_USER_PAGE'](state: IChatState, payload: number) {
    state.suggestedUserPage = payload;
  },
  ['RESET_SUGGESTED_USER_PAGE'](state: IChatState) {
    state.suggestedUserPage = defaultSuggestedUserDictionary.page;
  },
  ['SET_SUGGESTED_USER_PAGE_SIZE'](state: IChatState, payload: number) {
    state.suggestedUserPageSize = payload;
  },
  ['SET_SUGGESTED_USER_TOTAL'](state: IChatState, payload: number) {
    state.suggestedUserTotal = payload;
  },

  ['SET_CURRENT_CONTACT'](state: IChatState, payload: IChatContact) {
    state.currentContact = payload;
  },
  ['UPDATE_CURRENT_CONTACT_BY_MESSAGE'](state: IChatState, message: IChatMessage) {
    if (state.currentContact) {
      state.currentContact = {
        ...state.currentContact,
        content: message.content,
        dateTime: message.time,
      };
    }
  },
  ['SET_STOMP_CLIENT'](state: IChatState, payload: Client) {
    state.stompClient = payload;
  },

  ['SET_RECEIVED_MESSAGES'](state: IChatState, payload: IChatGroupMessage[]) {
    state.receivedMessages = [
      ...payload,
      ...(
        state.receivedMessages.length
          ? cloneObject(state.receivedMessages)
          : []
      ),
    ];
  },
  ['CLEAR_MESSAGES'](state: IChatState) {
    state.receivedMessages = [];
  },
  ['ADD_HISTORY_MESSAGES_TO_CURRENT_GROUP'](state: IChatState, messages: IChatMessage[]) {
    if (state.receivedMessages.length) {
      state.receivedMessages[0].messages.splice( 0, 0, ...messages);
    }
  },
  ['RESET_RECEIVED_MESSAGES'](state: IChatState) {
    state.receivedMessages = [];
  },
  ['SET_LAST_RECEIVED_MESSAGES'](state: IChatState, payload: IChatGroupMessage[]) {
    state.receivedMessages = [
      ...cloneObject(state.receivedMessages.slice(0, -1)),
      ...payload,
    ];
  },
  ['ADD_NEW_GROUP_MESSAGE_TO_RECEIVED_MESSAGES'](state: IChatState, payload: IChatGroupMessage[]) {
    state.receivedMessages.push(...payload);
  },

  ['SET_CHAT_COUNT'](state: IChatState, payload: number) {
    state.chatCount = payload;
  },
  ['SET_CHAT_PAGE'](state: IChatState, payload: number) {
    state.chatPage = payload;
  },
  ['RESET_CHAT_PAGE'](state: IChatState) {
    state.chatPage = defaultChatDictionary.page;
  },
  ['SET_CHAT_PAGE_SIZE'](state: IChatState, payload: number) {
    state.chatPageSize = payload;
  },
  ['SET_CHAT_TOTAL_PAGE'](state: IChatState, payload: number) {
    state.chatTotalPage = payload;
  },
  ['RESET_CHAT_TOTAL_PAGE'](state: IChatState) {
    state.chatTotalPage = defaultChatDictionary.total;
  },
  ['SET_CHAT_UPLOAD_DICTIONARY'](state: IChatState, payload: IDictionary<number>) {
    state.chatUploadDictionary = {
      ...state.chatUploadDictionary,
      ...payload
    };
  },
  ['SET_NEW_MESSAGE_CHAT_ID'](state: IChatState, payload: string) {
    state.newMessageChatId = payload;
  }
};

const actions: ActionTree<IChatState, RootState> = {
  connectChatChannel: ({state, commit, getters, dispatch}) => {
    commit('SET_LOADING', {chatConnect: true});
    const socket = new SockJS(`/api/${EnvProvider('API_VERSION')}/ws`);
    commit('SET_STOMP_CLIENT', Stomp.over(socket));

    if (getters.getUserInfo && getters.getUserInfo.id) {
      state.stompClient!.connect(
        { 'Authorization': `Bearer ${TokenService.getToken()}` },
        () => {
          state.stompClient!.subscribe(`/channel/${getters.getUserInfo.id}`,
            (tick) => {
              dispatch('menageChatChanel', tick);
            }, {});
        },
        (error) => console.warn(error)
      );
    }
    commit('SET_LOADING', {chatConnect: false});
  },
  menageChatChanel: ({state, getters, dispatch}, tick) => {
    const message = JSON.parse(tick.body);
    const isCurrentChat = getters.currentContact && getters.currentContact.chatId === message.chatId;
    if (isCurrentChat) {
      if (getters.getUserInfo.role !== message.sender) {
        state.stompClient!.send(`/message-read/${message.messageId}`);
      }
      dispatch('addLastMessageToReceivedMessages', message);
    }

    dispatch('updateChatContact', message);
  },
  disconnectChatChannel: ({state}, ) => {
    if (state.stompClient) {
      state.stompClient.disconnect();
    }
  },
  sendChatMessage: ({state, getters}, message: string|number) => {
    if (
      state.stompClient &&
      state.stompClient.connected &&
      getters.currentContact &&
      message
    ) {
      const data = JSON.stringify({
        content: message,
        chatId: getters.currentContact.chatId,
        sender: getters.getUserInfo.role
      });
      return new Promise((resolve) => {
        resolve(
          state.stompClient!.send('/message', data, {})
        );
      });
    }
  },

  addUploadMessageToReceivedMessages: ({commit, getters, dispatch}, payload: {file: File, messageId: string}) => {
    const {file, messageId} = payload;
    const message = generateUploadMessage({
      file,
      messageId,
      chatId: getters.currentContact.chatId,
      sender: getters.getUserInfo.role,
      time: new Date()
    });
    commit('SET_CHAT_UPLOAD_DICTIONARY', {[messageId]: 0});
    return dispatch('addLastMessageToReceivedMessages', message);
  },
  removeUploadMessageFromReceivedMessages: ({getters, commit}, messageId: string) => {
    const { date, messages } = generateLastGroupMessage(getters.receivedMessages);
    const groupMessage = [{
      date,
      messages: messages.filter((message: IChatMessage) => message.messageId !== messageId)
    }];
    commit('SET_LAST_RECEIVED_MESSAGES', groupMessage);
  },
  uploadChatFile: ({getters, dispatch}, payload: {file: File, messageId: string}) => {
    const {file, messageId} = payload;
    const formData = new FormData();
    formData.append('file', file);

    return ChatService.uploadChatFile(
      getters.currentContact.chatId,
      formData,
      (event) => dispatch('uploadChatProgress', { event, messageId })
    )
      .finally(() => dispatch('removeUploadMessageFromReceivedMessages', messageId));
  },
  uploadChatProgress: ({commit}, payload: { event: ProgressEvent, messageId: string }) => {
    const {event, messageId } = payload;
    const process = Math.round((100 * event.loaded) / event.total);
    commit('SET_CHAT_UPLOAD_DICTIONARY', {[messageId]: process});
  },

  getChatContactList: ({ commit, getters }, payload: IChatContactParameters) => {
    commit('SET_UNIFORM_LOADING', { chatContact: true });

    const queryParams = stringifyQueryParameters({
      pageSize: state.contactPageSize,
      ...(payload ? payload : []),
    } as IDictionary<string|number>);

    return ChatService.getChatContactList(queryParams)
      .then((data: IChatContacts) => {
        if (getters.contactList.length) {
          commit('MERGE_CONTACT_LIST', data.elements);
        } else {
          commit('SET_CONTACT_LIST', data.elements);
        }
        commit('SET_CONTACT_PAGE', data.currentPage);
        commit('SET_CONTACT_PAGE_SIZE', data.pageSize);
        commit('SET_CONTACT_TOTAL', data.totalResult);

      })
      .finally(() => commit('SET_UNIFORM_LOADING', { chatContact: false }));
  },
  resetChatContactList: ({commit}) => {
    commit('RESET_CONTACT_LIST');
  },
  getChatContactById: (_, id: string) => {
    return ChatService.getChatContactById(id);
  },
  getChatSuggestedUserList: ({commit}, payload?: IChatSuggestedUserParameters) => {
    commit('SET_UNIFORM_LOADING', {chatSuggestedUser: true});

    const queryParams = stringifyQueryParameters({
      pageSize: state.suggestedUserPageSize,
      ...(payload ? payload : [])
    } as IDictionary<string|number>);

    return ChatService.getChatSuggestedUserList(queryParams)
      .then((data: IChatSuggestedUsers) => {
        commit('SET_SUGGESTED_USER_LIST', data.elements);
        commit('SET_SUGGESTED_USER_PAGE', data.currentPage);
        commit('SET_SUGGESTED_USER_PAGE_SIZE', data.pageSize);
        commit('SET_SUGGESTED_USER_TOTAL', data.totalResult);
      })
      .finally(() => commit('SET_UNIFORM_LOADING', {chatSuggestedUser: false}));
  },
  resetChatSuggestedUserList: ({commit}) => {
    commit('RESET_SUGGESTED_USER_LIST');
  },
  setChatStart: ({commit, dispatch}, data) => {
    commit('SET_LOADING', {chatStart: true});
    return ChatService.setChatStart(data)
      .then((contact: IChatContact) => dispatch('setCurrentContact', { contact, isChatPage: data.isChatPage }))
      .finally(() => commit('SET_LOADING', {chatStart: false}));
  },
  setCurrentContact: ({commit, getters, dispatch}, data: { contact: IChatContact, isChatPage: boolean }) => {
    commit('RESET_RECEIVED_MESSAGES');
    commit('RESET_CHAT_PAGE');
    commit('RESET_CHAT_TOTAL_PAGE');

    const updatedContact = data.contact
      ? {
        ...data.contact,
        notReadMessages: 0
      }
      : {};
    const filteredContactList = getters.contactList.filter((item) => {
      return item.chatId !== data.contact.chatId;
    });
    commit('SET_CONTACT_LIST', [
      updatedContact,
      ...filteredContactList,
    ]);
    commit('SET_CURRENT_CONTACT', updatedContact);
    return data.isChatPage
      ? dispatch('getChatMessages')
        .finally(() => {
            dispatch('resetChatSuggestedUserList');
            dispatch('getChatSuggestedUserList');
          }
        )
      : dispatch('resetChatSuggestedUserList');
  },
  addLastMessageToReceivedMessages: ({commit, getters}, message: IChatMessage) => {
    const { isNewGroup, date, messages } = generateLastGroupMessage(getters.receivedMessages);
    const groupMessage = [{
      date,
      messages: [
        ...messages,
        message
      ]
    }];
    if (isNewGroup) {
      commit('ADD_NEW_GROUP_MESSAGE_TO_RECEIVED_MESSAGES', groupMessage);
    } else {
      commit('SET_LAST_RECEIVED_MESSAGES', groupMessage);
    }
    commit('UPDATE_CURRENT_CONTACT_BY_MESSAGE', message);
  },
  getChatMessages: ({getters, commit, dispatch}) => {
    const { chatId } = getters.currentContact;
    const isLoadMore = getters.chatPage <= Math.ceil(getters.chatTotalPage / getters.chatPageSize);
    if (chatId && isLoadMore) {
      const isInitMessages = getters.chatPage === 1;

      commit(
        isInitMessages ? 'SET_LOADING' : 'SET_UNIFORM_LOADING',
        {chatMessages: true}
      );

      const queryParams = stringifyQueryParameters({
        chatId,
        page: getters.chatPage,
        pageSize: 20
      } as IDictionary<string|number>);

      return ChatService.getChatMessages(queryParams)
        .then((data: IChatMessages) => {
          const sortedHistoryMessages = sortReceivedMessagesByDate(data.elements);

          return dispatch('manageFlowMessages', sortedHistoryMessages)
            .finally(() => {
              commit('SET_CHAT_PAGE', data.currentPage + 1);
              commit('SET_CHAT_PAGE_SIZE', data.pageSize);
              commit('SET_CHAT_TOTAL_PAGE', data.totalResult);
            });
        })
        .finally(() => {
          commit(
            isInitMessages ? 'SET_LOADING' : 'SET_UNIFORM_LOADING',
            {chatMessages: false}
          );
        });
    }
    return Promise.reject('No data');
  },
  manageFlowMessages: ({getters, commit}, sortedHistoryMessages) => {
    const currentGroupDate = getters.receivedMessages.length
      ? getters.receivedMessages[0].date
      : null;

    const filteredByNewDate = currentGroupDate
      ? sortedHistoryMessages.filter((historyMessage) => currentGroupDate !== historyMessage.date)
      : null;

    const filteredByOldDate = currentGroupDate
      ? sortedHistoryMessages.filter((historyMessage) => currentGroupDate === historyMessage.date)[0]
      : null;

    if (filteredByOldDate) {
      commit('ADD_HISTORY_MESSAGES_TO_CURRENT_GROUP', filteredByOldDate.messages);
    }
    commit('SET_RECEIVED_MESSAGES',
      filteredByOldDate
        ? filteredByNewDate
        : sortedHistoryMessages
    );
    return Promise.resolve();
  },
  updateChatContact: ({commit, getters, dispatch}, message: IChatMessage) => {
    const sender = getters.currentContact;
    const recipient = getters.contactList.filter((item: IChatContact) => {
      return item.chatId === message.chatId;
    })[0] || {};
    const contactListWithoutRecipient = getters.contactList.filter((item: IChatContact) => {
      return item.chatId !== message.chatId;
    });

    if (!Object.keys(recipient).length) {
      dispatch('getChatContactById', message.chatId)
        .then((recipient: IChatContact) => {
          const contactList = mergedContactList(sender, recipient, message, contactListWithoutRecipient);
          commit('SET_CONTACT_LIST', contactList);
        });
    } else {
      const contactList = mergedContactList(sender, recipient, message, contactListWithoutRecipient);
      commit('SET_CONTACT_LIST', contactList);
    }
  },

  getChatCount: ({commit}) => {
    return ChatService.chatCount()
    .then((data: IChatCount) => commit('SET_CHAT_COUNT', data.count))
    .catch((error) => Promise.reject(error));
  },
  streamChatCount: ({commit, getters}) => {
    getters.getStream.addEventListener('CHAT_COUNT_CHANGE', (data) => {
      commit('SET_CHAT_COUNT', JSON.parse(data.data).count);
    });
  },
  clearChatMessages: ({commit}) => {
    commit('CLEAR_MESSAGES');
  },
  clearChatCurrentContact: ({commit}) => {
    commit('SET_CURRENT_CONTACT', null);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
