import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {IAuthCredentials} from '@/interfaces';

export interface IAuthResponse {
  token: string;
}

export interface IAuthorizationService {
  logIn: (credentials: IAuthCredentials) => Promise<IAuthResponse>;
}

const logIn = (credentials: IAuthCredentials): Promise<IAuthResponse> => api
  .post('auth/tenant/login', credentials)
  .then((response: AxiosResponse<IAuthResponse>) => response.data)
  .catch((error) => Promise.reject(error));

const AuthorizationService: IAuthorizationService = {
  logIn
};

export default AuthorizationService;
