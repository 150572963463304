import {momentUTC, getTime, getFullDate} from '@/utilities';
import { i18n } from '@/main';

const reference = momentUTC(new Date());
const today = reference.clone().startOf('day');
const yesterday = reference.clone().subtract(1, 'days').startOf('day');
const later = reference.clone().subtract(1, 'days').startOf('day');

export const isToday = (date: Date) => momentUTC(date).isSame(today, 'd');
export const isYesterday = (date: Date) => momentUTC(date).isSame(yesterday, 'd');
export const isLater = (date: Date) => !(momentUTC(date).isAfter(later));

export const timestamps = (input: Date, isGroup: boolean = false) => {
  if (input) {
    if (isToday(input)) {
      return isGroup
        ? i18n.t('date.today')
        : getTime(input);
    }
    if (isYesterday(input)) { return i18n.t('date.yesterday'); }
    if (isLater(input)) { return getFullDate(input); }
  }
  return null;
};
