







































import {Component, Prop, Vue} from 'vue-property-decorator';
import {createHelpers} from 'vuex-map-fields';
import {sortList, sortType} from '@/interfaces';
import {getSortOptions} from '@/dictionaries/sort.dictionary';

const {mapFields} = createHelpers({
  getterType: 'getUiFields',
  mutationType: 'updateUiFields',
});

@Component({
  computed: {
    ...mapFields([
      'isTableSortActive'
    ])
  }
})
export default class TableSortDrawer extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: [sortList.updatedDesc]
  })
  private sortBy!: sortType[];

  private sortOptions: sortType = this.$route.query?.sort ? (this.$route.query.sort as sortType) : this.sortBy[0];

  private emitSortOptions(sortOptions) {
    this.$emit('sortOptions', getSortOptions(sortOptions));
  }

  private created() {
    this.emitSortOptions(this.sortOptions);
  }

  private setSortOptions() {
    this.$router.push({query: {...this.$route.query, sort: this.sortOptions}});
    this.$emit('setSortOptions');
  }
}
