import TokenService from '@/services/data/token.service';
import {CookieService} from '@/services/data/cookie.service';
import store from '@/store';

export const tokenGuard = (to, from, next) => {
  if (to.name === '500') { return next(); }

  const token = TokenService.getToken();
  if (to.name !== 'signIn' && !token) {
    store.commit('SET_SIGN_IN_REDIRECT_URL', window.location.href);
    return next({ name: 'signIn', params: to.params });
  } else {
    const promise = !store.getters.getUserFields('isAuthenticated')
      ? store.dispatch('authenticate')
      : Promise.resolve();

    return promise
      .then(() => {
        return to.name !== 'intro' && !CookieService.get('tenantIntroCompleted') && token
          ? next({ name: 'intro', params: to.params })
          : next();
      });
  }
};
