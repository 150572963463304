import { NavigationGuard } from 'vue-router';
import {
  getStorageLocale, loadLanguageAsync,
} from '@/plugins/vue-i18n';
import { LocaleService } from '@/utilities/locale';
import store from '@/store';

export const localeGuard: NavigationGuard = async (to, from, next) => {
  if (to.name === '500') { return next(); }

  const supportedLangs = !store.getters.getSupportedLangs
    ? await store.dispatch('localeSettings').then(() => store.getters.getSupportedLangs)
    : store.getters.getSupportedLangs;
  const user = await (!store.getters.getUserFields('isAuthenticated')
    ? store.dispatch('authenticate')
    : store.getters.getUserInfo);

  const countryCode = LocaleService.getCountryFromDomain();
  const userLocale = LocaleService.getLocaleCode(user?.language, countryCode);
  const paramLocale = LocaleService.getLocaleCode(to.params.lang, countryCode);
  const storageLocale = getStorageLocale();
  const predictLang = !store.getters.getPredictLang && !store.getters.getUserFields('isAuthenticated')
    ? await store.dispatch('predictLocale')
    : store.getters.getPredictLang;

  /**
   * LOCALE GETTER PRIORITY:
   * 1) User language from database
   * 2) Url parameter
   * 3) Language from localStorage
   * 4) predicted locale
   */

  const locale = userLocale && supportedLangs.includes(userLocale)
    ? userLocale
    : paramLocale && supportedLangs.includes(paramLocale)
      ? paramLocale
      : supportedLangs.includes(storageLocale)
        ? storageLocale
        : predictLang;

  const params = LocaleService.getSeparatedByCode(locale);
  if (to.params.lang !== params.lang) {
    // @ts-ignore
    return next({ ...to, params: { ...to.params, lang: params.lang } });
  }

  return loadLanguageAsync(locale).then(() => next());
};
