import { ILayout } from '@/interfaces';

import DefaultLayout from '@/layouts/Default.layout.vue';
import UILayout from '@/layouts/Ui.layout.vue';

export const layoutDictionary: ILayout[] = [
  {
    name: 'default',
    component: DefaultLayout
  },
  {
    name: 'ui',
    component: UILayout
  }
];
