



















































































import {Component, Vue, Prop} from 'vue-property-decorator';
import TableBtn from '@/components/Table/TableBtn.vue';
import {Currency, sortType} from '@/interfaces';
import {getValueByStatus} from '@/dictionaries/status.dictionary';
import {getFullDate} from '@/utilities';
import TableSortDrawer from '@/components/Table/Table.sort.drawer.vue';
import {createHelpers} from 'vuex-map-fields';
import TableToolbar from '@/components/Table/Table.toolbar.vue';
import {getFileUrl} from '@/utilities/getUrl';

const {mapFields} = createHelpers({
  getterType: 'getUiFields',
  mutationType: 'updateUiFields',
});

@Component({
  components: { TableBtn, TableSortDrawer, TableToolbar },
  computed: { ...mapFields(['isTableSettingsActive', 'isTableSortActive']) }
})
export default class Table extends Vue {
  private getValueByStatus = getValueByStatus;
  private getFullDate = getFullDate;
  private getFileUrl = getFileUrl;

  private get footerOptions(): object {
    return {
      prevIcon: '$chevronLeft',
      nextIcon: '$chevronRight',
      itemsPerPageText: this.$t('table.per_page'),
      itemsPerPageOptions: this.$store.getters.isMobileAdaptiveMode ? [5, 10, 15, 25] : [10, 25, 50, 100],
    };
  }

  private paginationOptions = {
    page: 0,
    itemsPerPage: 0
  };

  @Prop({ type: Number, required: false, default: 5 })
  private perPage!: number;

  @Prop({ type: Array, required: false, default: () => [] })
  private sortBy!: sortType[];

  private get attrs() {
    return {
      ...this.$attrs,
      'items-per-page': this.perPage,
      'footer-props': this.footerOptions,
      'disable-sort': true,
      'no-data-text': this.$t('table.noDataText'),
    };
  }

  private get actions() {
    return {
      pagination: (event) => {
        if (event.itemsPerPage === -1) {
          event.itemsPerPage = event.itemsLength;
        }

        this.$router.push({query: {...this.$route.query, page: event.page, itemsPerPage: event.itemsPerPage}});

        if (event.page !== this.paginationOptions.page || event.itemsPerPage !== this.paginationOptions.itemsPerPage) {
          this.$emit('pagination', event);
        }
        this.paginationOptions = event;
      }
    };
  }

  private formatCurrency(amount, currency = Currency.EUR) {
    return this.$options.filters?.[currency](amount) ?? amount;
  }

  private get formatRefNumber() {
    return (item) => '000000'.slice(String(item.refNumber).length) + item.refNumber;
  }
}
