























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Calendar extends Vue {
  private isPickerActive: boolean = false;

  @Prop({
    type: String,
    required: false,
    default: 'Date'
  })
  private label!: string;

  private get attrs(): object {
    return {
      ...this.$attrs,
      label: this.label,
      class: this.classObject,
    };
  }

  public get classObject() {
    return {
      disabled: !!this.$attrs.disabled,
    };
  }

  private get pickerEvents(): object {
    return {
      change: () => {
        this.isPickerActive = false;
      },
      input: (event: Event) => {
        return this.$emit('input', event);
      }
    };
  }

  private get computedDateFormatted(): string {
    if (this.$attrs.value) {
      const [year, month, day] = this.$attrs.value.split('-');
      return `${day}.${month}.${year}`;
    }
    return '';
  }
}
