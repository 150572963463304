
















import {Vue, Component, Prop} from 'vue-property-decorator';
import ExpansionIcon from '@/assets/icons/plus.svg';

@Component({
  inheritAttrs: false,
  components: {
    ExpansionIcon
  }
})
export default class Textarea extends Vue {
  @Prop({
    required: false,
    type: Array
  })
  private list!: [];
}
