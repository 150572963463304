import Vue, { VNode } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueI18n from 'vue-i18n';
import Vuetify from 'vuetify/lib/framework';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
// @ts-ignore
import themeStyles from '@/styles/stylus/variables/colors.styl';

import VueMeta from 'vue-meta';
import Vuebar from 'vuebar';
import VueChatScroll from 'vue-chat-scroll';
import VueMask from 'v-mask';
import InlineSvg from 'vue-inline-svg';

import Button from '@/components/Ui/Button.vue';
import Select from '@/components/Ui/Select.vue';
import Input from '@/components/Ui/Input.vue';
import Textarea from '@/components/Ui/Textarea.vue';
import Modal from '@/components/Ui/Modal.vue';
import Drawer from '@/components/Ui/Drawer.vue';
import Link from '@/components/Ui/Link.vue';
import Checkbox from '@/components/Ui/Checkbox.vue';
import Radio from '@/components/Ui/Radio.vue';
import Accordion from '@/components/Ui/Accordion.vue';
import Calendar from '@/components/Ui/Calendar.vue';
import Carousel from '@/components/Ui/Carousel.vue';
import Table from './components/Ui/Table.vue';
import ProgressBarRatio from './components/Ui/ProgressBarRatio.vue';
import NewPolicy from '@/components/Ui/NewPolicy.vue';
import Section from '@/components/Ui/Section.vue';
import SectionText from '@/components/Section/Section.text.vue';
import SectionTitle from '@/components/Section/Section.title.vue';
import Rating from '@/components/Ui/Rating.vue';
import StatisticCard from '@/components/StatisticCard.vue';
import Chip from '@/components/Ui/Chip.vue';
import Autocomplete from '@/components/Ui/Autocomplete.vue';
import InputPhone from '@/components/Ui/InputPhone.vue';

import {ICONS} from '@/dictionaries/icons.dictionary';

import Notification from 'vue-notification';
import PulseLoader from '@/components/PulseLoader.vue';
import VueCurrencyFilter from 'vue-currency-filter';
import {currencyOptions} from '@/dictionaries/symbols.currency.dictionary';
import {EnvProvider} from '@/utilities';
import {i18Options} from '@/plugins/vue-i18n';
import getStatic from '@/plugins/static.plugin';

Vue.config.productionTip = false;

Vue.use(getStatic);
Vue.use(VueI18n);
export const i18n = new VueI18n(i18Options);
Vue.use(Vuetify);
const vuetify = new Vuetify({
  icons: { values: ICONS },
  theme: {
    dark: false,
    default: 'light',
    options: {
      customProperties: false,
      variations: false
    },
    themes: {
      light: {
        primary: themeStyles.primaryColor,
        secondary: themeStyles.secondaryColor,
        tertiary: themeStyles.tertiaryColor,
        success: themeStyles.successColor,
        error: themeStyles.errorColor,
        warning: themeStyles.warningColor,
        info: themeStyles.infoColor,
      },
    }
  },
});

Vue.use(Vuebar);
Vue.use(Notification);
Vue.use(VueChatScroll);
Vue.use(VueCurrencyFilter, currencyOptions);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true,
});
Vue.use(VueMask);

Vue.component('Button', Button);
Vue.component('Select', Select);
Vue.component('Input', Input);
Vue.component('Textarea', Textarea);
Vue.component('Modal', Modal);
Vue.component('Drawer', Drawer);
Vue.component('Link', Link);
Vue.component('Checkbox', Checkbox);
Vue.component('Radio', Radio);
Vue.component('Accordion', Accordion);
Vue.component('Calendar', Calendar);
Vue.component('Carousel', Carousel);
Vue.component('Table', Table);
Vue.component('ProgressBarRatio', ProgressBarRatio);
Vue.component('NewPolicy', NewPolicy);
Vue.component('Section', Section);
Vue.component('SectionText', SectionText);
Vue.component('SectionTitle', SectionTitle);
Vue.component('Rating', Rating);
Vue.component('StatisticCard', StatisticCard);
Vue.component('Chip', Chip);
Vue.component('PulseLoader', PulseLoader);
Vue.component('Autocomplete', Autocomplete);
Vue.component('InputPhone', InputPhone);
Vue.component('SvgInline', InlineSvg);

const render = (createElement: typeof Vue.prototype.$createElement): VNode => createElement(App);

Sentry.init({
  Vue,
  dsn: EnvProvider('SENTRY_DSN'),
  ignoreErrors: ['Request failed with status code 401'],
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [EnvProvider('URL'), /^\//],
    }),
  ],
  tracesSampleRate: parseFloat(EnvProvider('SENTRY_TRACES_SAMPLE_RATE')),
  environment: EnvProvider('SENTRY_ENVIRONMENT'),
  release: EnvProvider('SENTRY_RELEASE'),
  logErrors: true
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render
}).$mount('#app');
