























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class StatisticCard extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  private gradient!: false;

  @Prop({
    type: String,
    required: true
  })
  private title!: string;

  @Prop({
    type: Number,
    required: false
  })
  private firstNumber!: number;

  @Prop({
    type: Number,
    required: true
  })
  private secondNumber!: number;

  @Prop({
    type: String,
    required: true
  })
  private icon!: string;

  private get classObject(): object {
    return {
      gradient: this.gradient
    };
  }
}
