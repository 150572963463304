









































import {Component, Vue} from 'vue-property-decorator';
import LogoIcon from '@/components/Layout/Logo.vue';
import Alert from '@/components/Layout/Header/Header.alert.vue';
import HeaderNotifications from '@/components/Layout/Header/Header.notifications.vue';
import HeaderMenu from '@/components/Layout/Header/Header.menu.vue';
import HeaderMenuBtn from '@/components/Layout/Header/Header.menu.btn.vue';

@Component({
  components: {
    LogoIcon,
    Alert,
    HeaderNotifications,
    HeaderMenu,
    HeaderMenuBtn,
  }
})
export default class Header extends Vue {
  private isHeaderDrawer: boolean = false;

  public mounted(): void {
    this.$root.$on(
      'isHeaderDrawer',
      (value) => this.isHeaderDrawer = value
    );
  }

  private get getNotifications() {
    return this.$store.getters.getNotifications;
  }

  private get getNotificationCount() {
    return this.$store.getters.getNotificationsCount;
  }

  private get isIntroPage() {
    return this.$route.name === 'intro';
  }
}
