










import {Component, Vue} from 'vue-property-decorator';
import Navigation from '@/components/Layout/Navigation.vue';

@Component({
  components: { Navigation }
})
export default class HeaderMenu extends Vue {}
