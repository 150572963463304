import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {helpSourceType, IUserInfo} from '@/interfaces';

export interface IPasswordChangeInfo {
  password: string;
  reEnteredPassword: string;
}

export interface IConfirmResetPassword {
  password: string;
  reEnteredPassword: string;
  token: string;
}

export interface IHelpData {
  message: string;
  source: helpSourceType;
}

export interface IUserService {
  getUserInfo: () => Promise<null | IUserInfo>;
  getUserInfoPayment: (id: string) => Promise<null | IUserInfo>;
  setUserInfo: (data: IUserInfo) => Promise<null | IUserInfo>;
  setNewPassword: (data: IPasswordChangeInfo) => Promise<null | IUserInfo>;
  resetPassword: (email: string) => Promise<null | IUserInfo>;
  confirmResetPassword: (data: IConfirmResetPassword) => Promise<null | IUserInfo>;
  getStripeKey: () => Promise<string>;
  help: (help: IHelpData) => Promise<object>;
}

const getUserInfo = (): Promise<IUserInfo> => api
  .get('/user/profile/tenant')
  .then((response: AxiosResponse<IUserInfo>) => response.data)
  .catch((error) => Promise.reject(error));

const getUserInfoPayment = (id: string): Promise<IUserInfo> => api
  .get(`/user/profile/payment?id=${id}`)
  .then((response: AxiosResponse<IUserInfo>) => response.data)
  .catch((error) => Promise.reject(error));

const setUserInfo = (data: IUserInfo): Promise<IUserInfo> => api
  .put('/user/profile/tenant', data)
  .then((response: AxiosResponse<IUserInfo>) => response.data)
  .catch((error) => Promise.reject(error));

const setNewPassword = (data: IPasswordChangeInfo): Promise<IUserInfo> => api
  .patch('/user/change-password', data)
  .then((response: AxiosResponse<IUserInfo>) => response.data)
  .catch((error) => Promise.reject(error));

const resetPassword = (email: string): Promise<IUserInfo> => api
  .post(`/user/tenant/reset-password?email=${email}`)
  .then((response: AxiosResponse<IUserInfo>) => response.data)
  .catch((data) => Promise.reject(data));

const confirmResetPassword = (data: IConfirmResetPassword): Promise<IUserInfo> => api
  .post('/user/tenant/confirm-reset-password', data)
  .then((response: AxiosResponse<IUserInfo>) => response.data)
  .catch((error) => Promise.reject(error));

const getStripeKey = (): Promise<string> => api
  .post('/user/change-payment')
  .then((response: AxiosResponse<string>) => response.data)
  .catch((error) => Promise.reject(error));

const help = (helpData: IHelpData) => api
  .post('/help', helpData)
  .then((response: AxiosResponse<object>) => response.data)
  .catch((error) => Promise.reject(error));

const UserService: IUserService = {
  getUserInfo,
  getUserInfoPayment,
  setUserInfo,
  setNewPassword,
  resetPassword,
  confirmResetPassword,
  getStripeKey,
  help,
};

export default UserService;
