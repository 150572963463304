














































import {Component, Prop, Vue} from 'vue-property-decorator';
import NotificationItem from '@/components/Layout/Header/Header.notification.item.vue';
import PulseLoader from '@/components/PulseLoader.vue';
import {INotification} from '@/interfaces';
import {createHelpers} from 'vuex-map-fields';
import HeaderNotificationSkeleton from '@/components/Layout/Header/Header.notification.skeleton.vue';
import HeaderNoNotifications from '@/components/Layout/Header/Header.no-notifications.vue';

const {mapFields} = createHelpers({
  getterType: 'getUiFields',
  mutationType: 'updateUiFields',
});

@Component({
  components: {
    NotificationItem,
    PulseLoader,
    HeaderNotificationSkeleton,
    HeaderNoNotifications
  },
  computed: {
    ...mapFields(['isNotificationActive'])
  }
})
export default class HeaderNotifications extends Vue {
  private isNotificationActive!: boolean;
  private intersectActive: boolean = true;

  @Prop({ type: Array, required: false })
  private notifications!: INotification[];

  private closeNotification(event: string | number) {
    this.notifications.splice(this.notifications.findIndex((item) => item.id === event), 1);
  }

  private loadNotificationsMore(_, isIntersecting) {
    if (isIntersecting) {
      this.intersectActive = false;
      this.$store.dispatch('getNotifications', this.$store.getters.getNotificationsPage + 1)
        .catch((error) => this.$store.commit('SET_ERROR_DATA', error))
        .finally(() => this.intersectActive = true);
    }
  }

  private get isNotificationsExist() {
    return !this.$store.getters.isUniformLoading('skeletonLoader') && !this.notifications.length;
  }
}
