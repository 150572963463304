import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import {
  IClaim,
  IClaimDetails,
  IExplainClaimData,
  IRemoveFile,
  IResponsePageableArray,
  IUploadedFileData, UploadOriginEnum,
} from '@/interfaces';
import ClaimApiService from '@/services/api/claim.service';
import { IUploadFile } from '@/components/Ui/Uploader/interfaces';

export interface IClaimState {
  claimUploadedFileData: IUploadedFileData[];
  claims: IClaim[];
  claim: IClaim;
  claimsCount: number;
}

const initialState = () => ({
  claimUploadedFileData: [],
  claims: [],
  claim: {},
  claimsCount: null,
});
const state = initialState();

const getters = {
  getClaimUploadedFiles: (state) => state.claimUploadedFileData,
  getClaims: (state: IClaimState) => state.claims,
  getClaim: (state: IClaimState) => state.claim,
  getClaimsCount: (state: IClaimState) => state.claimsCount,
};

const mutations = {
  ['SET_CLAIM_UPLOADED_FILE'](state: IClaimState, payload: IUploadedFileData) {
    state.claimUploadedFileData.push(payload);
  },
  ['REMOVE_CLAIM_UPLOAD_FILE'](state: IClaimState, payload: IRemoveFile) {
    state.claimUploadedFileData = state.claimUploadedFileData.filter((item) => item.id !== payload.documentId);
  },
  ['REMOVE_CLAIM_UPLOADED_FILES'](state: IClaimState) {
    state.claimUploadedFileData = [];
  },
  ['SET_CLAIMS'](state: IClaimState, payload: IClaim[]) {
    state.claims = payload;
  },
  ['SET_CLAIM'](state: IClaimState, payload: IClaim) {
    state.claim = payload;
  },
  ['SET_CLAIMS_COUNT'](state: IClaimState, payload: number) {
    state.claimsCount = payload;
  },
  ['REMOVE_CLAIM_TENANT_DOCUMENT_BY_ID'](state: IClaimState, payload: IUploadFile[]) {
    state.claim.tenantDocuments = payload;
  },
};

const actions: ActionTree<IClaimState, RootState> = {
  setNewClaimFile: ({commit}, payload) => {
    commit('SET_LOADING', {uploadFile: true});
    return ClaimApiService.uploadFile(payload)
      .then((data: IUploadedFileData) => {
        commit('SET_CLAIM_UPLOADED_FILE', data);
      })
      .finally(() => commit('SET_LOADING', {uploadFile: false}));
  },
  removeClaimFile: ({commit}, payload: IRemoveFile) => {
    commit('SET_LOADING', {removeFile: true});
    return ClaimApiService.removeFile(payload)
      .then(() => {
        commit('REMOVE_CLAIM_UPLOAD_FILE', payload);
      })
      .finally(() => commit('SET_LOADING', {removeFile: false}));
  },
  getClaims: ({commit}, data) => {
    commit( 'SET_UNIFORM_LOADING', {getClaims: true});
    return ClaimApiService.getClaims(data)
      .then((data: IResponsePageableArray) => {
        commit('SET_CLAIMS_COUNT', data.totalResult);
        commit('SET_CLAIMS', data.elements);
      })
      .finally(() => commit('SET_UNIFORM_LOADING', {getClaims: false}));
  },
  getClaim: ({commit}, id: string) => {
    commit('SET_LOADING', {getClaims: true});
    return ClaimApiService.getClaim(id)
      .then((data: IClaimDetails) => commit('SET_CLAIM', data))
      .finally(() => commit('SET_LOADING', {getClaims: false}));
  },
  acceptClaim: ({commit}, id: string) => {
    commit('SET_LOADING', {acceptClaim: true});
    return ClaimApiService.acceptClaim(id)
      .then((data: IClaimDetails) => {
        commit('SET_CLAIM', data);
      })
      .finally(() => commit('SET_LOADING', {acceptClaim: false}));
  },
  explainClaim: ({commit}, data: IExplainClaimData) => {
    commit('SET_LOADING', {setClaim: true});
    return ClaimApiService.explainClaim(data)
      .finally(() => commit('SET_LOADING', {setClaim: false}));
  },
  clearClaimFiles: ({commit}) => {
    commit('REMOVE_CLAIM_UPLOADED_FILES');
  },
  uploadClaimTenantDocuments: (_, {file, id}: {file: File, id: string}) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    formData.append('origin', UploadOriginEnum.CLAIM_TENANT);

    return ClaimApiService.uploadFile(formData);
  },
  removeClaimTenantDocuments: ({dispatch}, payload: {documentId: string, id: string}) => {
    return ClaimApiService.removeFile(payload)
      .then(() => dispatch('removeClaimTenantDocumentById', payload.documentId));
  },
  removeClaimTenantDocumentById: ({commit, getters}, documentId: string) => {
    const tenantDocuments = getters.getClaim.tenantDocuments.filter((item) => item.id !== documentId);
    commit('REMOVE_CLAIM_TENANT_DOCUMENT_BY_ID', tenantDocuments);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
