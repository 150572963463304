import { render, staticRenderFns } from "./Header.notifications.vue?vue&type=template&id=1875dd7c&scoped=true&lang=pug&"
import script from "./Header.notifications.vue?vue&type=script&lang=ts&"
export * from "./Header.notifications.vue?vue&type=script&lang=ts&"
import style0 from "./Header.notifications.vue?vue&type=style&index=0&id=1875dd7c&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1875dd7c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
