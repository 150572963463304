import {NotificationType, NotificationTypeList} from '@/interfaces';
import router from '@/router';
import {EnvProvider} from '@/utilities';
import store from '@/store';
import {LocaleService} from '@/utilities/locale';

export const NotificationIcon = {
  [NotificationTypeList.LANDLORD_CLAIM_FILED]: '$claimIcon',
  [NotificationTypeList.TENANT_CLAIM_FILED]: '$claimIcon',
  [NotificationTypeList.CLAIM_ACCEPTED]: '$claimIcon',
  [NotificationTypeList.TENANT_CLAIM_PAID]: '$claimIcon',
  [NotificationTypeList.LANDLORD_CLAIM_REJECTED]: '$claimIcon',
  [NotificationTypeList.TENANT_CLAIM_REJECTED]: '$claimIcon',
  [NotificationTypeList.LANDLORD_POLICY_CANCELLATION_REQUESTED]: '$policy',
  [NotificationTypeList.LANDLORD_POLICY_CANCELED]: '$policy',
  [NotificationTypeList.TENANT_POLICY_CANCELED]: '$policy',
  [NotificationTypeList.TENANT_POLICY_PAYMENT_RECEIVED]: '$policy',
  [NotificationTypeList.AUTOMATIC_TENANT_POLICY_CANCELED]: '$policy',
  [NotificationTypeList.LANDLORD_PROPERTY_ADDED]: '$property',
  [NotificationTypeList.TENANT_CERTIFICATE_ADDED]: '$missingDocument',
  [NotificationTypeList.TENANT_INVITED]: '$property',
  [NotificationTypeList.LANDLORD_INVITATION_CANCELED]: '$property',
  [NotificationTypeList.TENANT_INVITATION_WAITING]: '$property',
  [NotificationTypeList.LANDLORD_INVITATION_ACCEPTED]: '$property',
  [NotificationTypeList.TENANT_MISSING_DOCUMENTS]: '$missingDocument',
  [NotificationTypeList.PASSWORD_CHANGED]: '$account',
  [NotificationTypeList.NEW_MESSAGE]: '$newMessage',
  [NotificationTypeList.HELP_REQUEST_SENT]: '$helpIcon',
};

export const getNotificationIcon = (type: NotificationType): string => NotificationIcon[type];

export const NotificationTypePath = {
  [NotificationTypeList.TENANT_CLAIM_FILED]: (id) =>
    router.push({name: 'claim-details', params: {id}}),
  [NotificationTypeList.TENANT_CLAIM_PAID]: (paymentToken) =>
    window.open(`${LocaleService.replaceDomain(EnvProvider('URL_CHECKOUT'))}/claim-payment/${paymentToken}`, '_blank'),
  [NotificationTypeList.TENANT_POLICY_CANCELED]: () =>
    window.open(EnvProvider('URL_WEBSITE'), '_blank'),
  [NotificationTypeList.TENANT_POLICY_PAYMENT_RECEIVED]: (id) =>
    router.push({name: 'invoices', params: {id}}),
  [NotificationTypeList.TENANT_MISSING_DOCUMENTS]: (id) =>
    router.push({name: 'policy', params: {id}, query: {tab: '1'}}),
  [NotificationTypeList.TENANT_CERTIFICATE_ADDED]: (id) =>
    router.push({name: 'policy', params: {id}, query: {tab: '1'}}),
  [NotificationTypeList.NEW_MESSAGE]: (id) => {
    if (router.currentRoute.name === 'chat') {
      store.dispatch('getChatContactById', id)
        .then((contact) => store.dispatch('setCurrentContact', { contact, isChatPage: true }))
        .finally(() => store.commit('SET_NEW_MESSAGE_CHAT_ID', null));
    } else {
      store.commit('SET_NEW_MESSAGE_CHAT_ID', id);
      router.push({name: 'chat'});
    }
  },
};
