import { AxiosRequestConfig } from 'axios';
import TokenService from '@/services/data/token.service';

const requestInterceptor = (config: AxiosRequestConfig) => {
  if (['/settings', '/lang'].includes(config.url ?? '')) {
    return config;
  }
  const token = TokenService.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export default requestInterceptor;
