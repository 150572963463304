



















import {Vue, Component, Prop} from 'vue-property-decorator';
import CloseIcon from '@/assets/icons/close_icon.svg';

@Component({
  inheritAttrs: false,
  components: {
    CloseIcon
  }
})
export default class Drawer extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '390'
  })
  private maxWidth!: string;

  private get getAttrs() {
    return {
      ...this.$attrs,
      style: this.styleObject,
      temporary: true,
      fixed: true,
      right: true,
      bottom: this.$store.getters.isMobileAdaptiveMode,
      width: '100%'
    };
  }

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  private closeBtn!: boolean;

  private get styleObject() {
    return {
      'max-width': this.$store.getters.isMobileAdaptiveMode ? '100%' : `${this.maxWidth}px`
    };
  }

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private hr!: boolean;
}
