var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('TableToolbar',{attrs:{"isSortBy":!!_vm.sortBy.length},on:{"search":function($event){return _vm.$emit('search', $event)}},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_vm._t("buttons")]},proxy:true}],null,true)}),_c('v-data-table',_vm._g(_vm._b({staticClass:"data-table",scopedSlots:_vm._u([{key:"header.charged",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"charged"},[_vm._v(_vm._s(header.text))])]}},{key:"item.refNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatRefNumber(item)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('Chip',{attrs:{"color":_vm.getValueByStatus(item.status).color},domProps:{"innerHTML":_vm._s(_vm.$t(_vm.getValueByStatus(item.status).text))}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.date ? _vm.getFullDate(item.date) : '-'))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount ? _vm.formatCurrency(item.amount, item.currency) : '-'))])]}},{key:"item.charged",fn:function(ref){
var item = ref.item;
return [(!!item.charged)?_c('span',{staticClass:"charged"},[_vm._v(_vm._s(item.charged ? _vm.formatCurrency(item.charged, item.currency) : '-'))]):_c('span',{staticClass:"charged"},[_vm._v("-")])]}},{key:"item.link",fn:function(ref){
var isMobile = ref.isMobile;
var item = ref.item;
return [(isMobile)?_c('table-btn',{staticClass:"mobile-btn chevron_btn",on:{"click":function($event){return _vm.$emit('clickLink', item.id)}}},[_c('Link',{attrs:{"to":"#","title":_vm.$t('table.vwDetails')}}),_c('v-icon',[_vm._v("$chevronRight")])],1):_c('table-btn',{staticClass:"chevron_btn",attrs:{"width":"24"},on:{"click":function($event){return _vm.$emit('clickLink', item.id)}}},[_c('v-icon',[_vm._v("$chevronRight")])],1)]}},{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [(item.document && item.document.url)?_c('table-btn',{staticClass:"chevron_btn",attrs:{"href":_vm.getFileUrl(item.document.url),"target":"_blank","width":"24"}},[_c('v-icon',[_vm._v("$chevronRight")])],1):_vm._e()]}},{key:"item.arrow",fn:function(){return [_c('table-btn',{attrs:{"width":"24"}},[_c('v-icon',[_vm._v("$arrowForward")])],1)]},proxy:true},{key:"item.more",fn:function(){return [_c('table-btn',{attrs:{"width":"25"}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("$infoBtn")])],1)]},proxy:true}])},'v-data-table',_vm.attrs,false),_vm.actions)),(_vm.sortBy.length)?_c('TableSortDrawer',{attrs:{"sortBy":_vm.sortBy},on:{"sortOptions":function($event){return _vm.$emit('sortOptions', $event)},"setSortOptions":function($event){return _vm.$emit('setSortOptions')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }