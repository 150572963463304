import { i18n } from '@/main';
import { LocaleService } from '@/utilities/locale';
import { api } from '@/services/api/api.service';
import de from '@/languages/translations/de';
import {I18nOptions} from 'vue-i18n';

const defaultLocale: Record<string, string> = {
  de: 'de-DE',
  at: 'de-AT',
  nl: 'de-NL',
};

const loadedLanguages = ['de'];

export const getStorageLocale = () => {
  return localStorage.getItem('locale') || '';
};

export function getLocale(): string {
  return i18n.locale;
}
export const getFallbackLocale = (): string => {
  return i18n.fallbackLocale as string;
};

export const getLang = (): string => {
  return LocaleService.getSeparatedByCode(i18n.locale).lang;
};

export const getCountry = (): string => {
  return LocaleService.getSeparatedByCode(i18n.locale).country;
};
export const setI18nLanguage = (locale: string) => {
  i18n.locale = locale;
  api.defaults.headers['Accept-Language'] = locale;
  document.querySelector('html')!.setAttribute('lang', locale);
  localStorage.setItem('locale', locale);
  return locale;
};

const getResourceMessages = (r: any) => r.default || r;

export const loadLanguageAsync = async (locale = 'en') => {
  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    return Promise.resolve(setI18nLanguage(locale));
  }

  // If the language hasn't been loaded yet
  const message = await import(/* webpackChunkName: "lang-[request]" */ `@/languages/translations/${locale}`)
    .then(getResourceMessages)
    .catch(async () => {
      // Fix locale file names
      const localeName = locale.split('-')[0];
      return getResourceMessages(await import(/* webpackChunkName: "lang-[request]" */ `@/languages/translations/${localeName}`));
    });
  i18n.setLocaleMessage(locale, message);
  return setI18nLanguage(locale);
};

export const i18Options: I18nOptions = {
  locale: defaultLocale[LocaleService.getCountryFromDomain()],
  messages: {de}
};
