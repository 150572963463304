import { VueConstructor } from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { IUploadFile } from '@/components/Ui/Uploader/interfaces';

export * from './user';

export interface IDictionary<T> {
  [key: string]: T;
}

export type ValueOf<T> = T[keyof T];

export interface ILayout {
  name: string;
  component: VueConstructor;
}

export interface IIcons {
  [key: string]: {
    component: VueConstructor,
    props?: {
      name: string,
    },
  };
}

export interface IResponsePageableArray {
  currentPage: number;
  totalResult: number;
  pageSize: number;
  sortField: string;
  sortDirection: string;
  elements: [];
}

export interface IAuthCredentials {
  login: string;
  password: string;
  rememberMe: boolean;
}

export interface IRouterGuard {
  to: Route;
  from: Route;
  next: NavigationGuardNext;
  nextGuard?: (params?: IRouterGuard) => void;
}

export type CurrencyType = 'EUR' | 'USD' | 'GBP';

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
}

export enum InvoiceStatusEnum {
  PROGRESS = 'PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED'
}

export enum PolicyStatusEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  CANCELLATION = 'CANCELLATION',
  CANCELED = 'CANCELED',
}

export enum ClaimStatusEnum {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  INVOICING = 'INVOICING',
  OVERDUE = 'OVERDUE',
  CANCELED = 'CANCELED',
  ACCEPTED = 'ACCEPTED',
  RESOLVED = 'RESOLVED',
  UNPAID = 'UNPAID'
}

export type StatusType
  = InvoiceStatusEnum
  | PolicyStatusEnum
  | ClaimStatusEnum;

export enum UploadTypeEnum {
  RENT_CONTRACT = 'RENT_CONTRACT',
  INVENTORY_PROTOCOL = 'INVENTORY_PROTOCOL',
  TENANT_ATTACHMENTS = 'TENANT_ATTACHMENTS',
  LANDLORD_ATTACHMENTS = 'LANDLORD_ATTACHMENTS',
}

export type UploadType = ValueOf<UploadTypeEnum>;

export enum UploadOriginEnum {
  ONBOARDING = 'ONBOARDING',
  POLICY = 'POLICY',
  CLAIM_TENANT = 'CLAIM_TENANT',
  CLAIM_LANDLORD = 'CLAIM_LANDLORD',
  CHAT_ATTACHMENT = 'CHAT_ATTACHMENT',
  INSURANCE_PRODUCT = 'INSURANCE_PRODUCT',
  INVOICE = 'INVOICE'
}

export type UploadOrigin = ValueOf<UploadOriginEnum>;

export interface IUploadedFileData {
  id: string;
  fileName: string;
  fileType: string;
  url: string;
  type: string;
  size: string;
  createdAt: string;
}

export interface IRemoveFile {
  id: string;
  documentId: string;
}

export interface INotification {
  id: string;
  title: string;
  content: string;
  contextId: string;
  type: NotificationType;
  createdAt: string;
}

export type NotificationType = NotificationTypeList;

export enum NotificationTypeList {
  LANDLORD_CLAIM_FILED = 'LANDLORD_CLAIM_FILED',
  TENANT_CLAIM_FILED = 'TENANT_CLAIM_FILED',
  CLAIM_ACCEPTED = 'CLAIM_ACCEPTED',
  TENANT_CLAIM_PAID = 'TENANT_CLAIM_PAID',
  LANDLORD_CLAIM_REJECTED = 'LANDLORD_CLAIM_REJECTED',
  TENANT_CLAIM_REJECTED = 'TENANT_CLAIM_REJECTED',
  LANDLORD_POLICY_CANCELLATION_REQUESTED = 'LANDLORD_POLICY_CANCELLATION_REQUESTED',
  LANDLORD_POLICY_CANCELED = 'LANDLORD_POLICY_CANCELED',
  TENANT_POLICY_CANCELED = 'TENANT_POLICY_CANCELED',
  TENANT_POLICY_PAYMENT_RECEIVED = 'TENANT_POLICY_PAYMENT_RECEIVED',
  AUTOMATIC_TENANT_POLICY_CANCELED = 'AUTOMATIC_TENANT_POLICY_CANCELED',
  LANDLORD_PROPERTY_ADDED = 'LANDLORD_PROPERTY_ADDED',
  TENANT_CERTIFICATE_ADDED = 'TENANT_CERTIFICATE_ADDED',
  TENANT_INVITED = 'TENANT_INVITED',
  LANDLORD_INVITATION_CANCELED = 'LANDLORD_INVITATION_CANCELED',
  TENANT_INVITATION_WAITING = 'TENANT_INVITATION_WAITING',
  LANDLORD_INVITATION_ACCEPTED = 'LANDLORD_INVITATION_ACCEPTED',
  TENANT_MISSING_DOCUMENTS = 'TENANT_MISSING_DOCUMENTS',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
  NEW_MESSAGE = 'NEW_MESSAGE',
  HELP_REQUEST_SENT = 'HELP_REQUEST_SENT',
}

export interface IPolicyItem {
  address: Partial<IPolicyAddress>;
  attachments: IUploadFile[];
  cancelled: boolean;
  claimCharged: number;
  depositCoverage: number;
  id: string;
  name: string;
  currency: string;
  missingDocuments: boolean;
  refId: string;
  lastInvoice: Partial<IPolicyLastInvoice>;
  premiumAmount: number;
}

export interface IPolicyAddress {
  city: string;
  country: string;
  street: string;
  streetNumber: string;
  zip: string;
}

export interface IPolicyLastInvoice {
  amount: number;
  currency: CurrencyType;
  date: Date;
  description: string;
  document: null;
  id: string;
  status: keyof typeof InvoiceStatusEnum;
}

export interface IPolicyFullItem extends IPolicyItem {
  contractualDocuments: string[];
  inventoryProtocol: null | {};
  landlordName: string;
  paymentPeriod: string;
  rentContract: null | {};
  validDate: string;
  claims: IPolicyClaim[];
  refId: string;
  startDate: string;
  endDate: string;
}

export interface IPolicyClaim {
  address: string;
  amount: number;
  currency: string;
  damagePlace: string;
  damageTypes: string[];
  date: string;
  description: string;
  id: string;
  justificationMessage: string;
  landlord: string;
  status: string;
  tenantDocuments: [];
  type: string;
}

export interface IPolicyInvoice {
  amount: number;
  currency: string;
  date: string;
  status: string;
}

export type UserType = 'landlord' | 'tenant';

export type SenderRoleType = 'ROLE_TENANT' | 'ROLE_LANDLORD';
export enum SenderRole {
  ROLE_TENANT = 'ROLE_TENANT',
  ROLE_LANDLORD = 'ROLE_LANDLORD',
}

export type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
  currentTarget: T;
};

export type MediaExtensionType
  = 'zip' | 'webdvd.zip'
  | 'mp4' | 'mov' | 'flv' | 'mpg' | 'wmv'
  | 'jpg' | 'jpeg' | 'png' | 'tif'
  | 'mp3' | 'wav' | 'ogg'
  | 'html' | 'htm'
  | 'doc' | 'pdf' | 'docx' | 'ppt' | 'pptx' | 'xls' | 'xlsx'
  | 'swf' | 'bin' | 'exe' | 'flipchart' | 'gxt' | 'iso' | 'jcw'
  | 'lld' | 'lsc' | 'lso' | 'mbz' | 'mdb' | 'mtt' | 'piv'
  | 'stk' | 'sxd' | 'sxi' | 'xtm';

export const MediaExtensions: IDictionary<MediaExtensionType> = {
  zip: 'zip', webdvdzip: 'webdvd.zip',
  mp4: 'mp4', mov: 'mov', flv: 'flv', mpg: 'mpg', wmv: 'wmv',
  jpg: 'jpg', jpeg: 'jpeg', png: 'png', tif: 'tif',
  mp3: 'mp3', ogg: 'ogg', wav: 'wav',
  html: 'html', htm: 'htm',
  doc: 'doc', pdf: 'pdf', docx: 'docx', ppt: 'ppt', pptx: 'pptx', xls: 'xls', xlsx: 'xlsx',
  swf: 'swf', bin: 'bin', exe: 'exe', flipchart: 'flipchart', gxt: 'gxt', iso: 'iso', jcw: 'jcw',
  lld: 'lld', lsc: 'lsc', lso: 'lso', mbz: 'mbz', mdb: 'mdb', mtt: 'mtt', piv: 'piv', stk: 'stk',
  sxd: 'sxd', sxi: 'sxi', xtm: 'xtm'
};

export interface IChatUploadDictionary {
  [key: string]: number;
}

export interface IClaim {
  id: string;
  refNumber: string;
  status: StatusType;
  damageAmount: number;
  date: Date;
  type: ClaimType;
  amount: number;
  currency: {
    currencyCode: string;
    defaultFractionDigits: number;
    numericCode: number;
    numericCodeAsString: string;
    displayName: string;
    symbol: string;
  };
  description: string;
  justificationMessage: string;
  damagePlace: DamagedPlaces;
  damageTypes: DamagedType[];
  landlordDocuments: IUploadFile[];
  rentContract: IUploadFile[];
  inventoryProtocol: IUploadFile;
  tenantDocuments: IUploadFile[];
  address: string;
  policyId: string;
  tenant: string;
  landlord: string;
  paymentToken: string;
  untilDate: string;
  beneficiaryName: string;
}

export type ClaimType = 'EXCESSIVE_DAMAGE' | 'RENT_NOT_PAID' | 'UTILITIES_NOT_PAID';

export type DamagedPlaces = 'BATHROOM' | 'LIVING_ROOM' | 'HALLWAY' | 'BEDROOM' | 'KITCHEN' | 'BUILDING_COMMON_AREA' |
  'HEATING' | 'ELECTRICAL';

export type DamagedType = 'FLOOR' | 'DOORS' | 'WALLS' | 'WINDOWS' | 'FURNITURE' | 'STAIRS' | 'APPLIANCES' |
  'LIGHTNING' | 'OTHER';

export enum ClaimTypeList {
  EXCESSIVE_DAMAGE = 'EXCESSIVE_DAMAGE',
  RENT_NOT_PAID = 'RENT_NOT_PAID',
  UTILITIES_NOT_PAID = 'UTILITIES_NOT_PAID',
}

export enum DamagedPlacesList {
  BATHROOM = 'BATHROOM',
  LIVING_ROOM = 'LIVING_ROOM',
  HALLWAY = 'HALLWAY',
  BEDROOM = 'BEDROOM',
  KITCHEN = 'KITCHEN',
  BUILDING_COMMON_AREA = 'BUILDING_COMMON_AREA',
  HEATING = 'HEATING',
  ELECTRICAL = 'ELECTRICAL',
}

export enum DamagedTypeList {
  FLOOR = 'FLOOR',
  DOORS = 'DOORS',
  WALLS = 'WALLS',
  WINDOWS = 'WINDOWS',
  FURNITURE = 'FURNITURE',
  STAIRS = 'STAIRS',
  APPLIANCES = 'APPLIANCES',
  LIGHTNING = 'LIGHTNING',
  OTHER = 'OTHER',
}

export enum addressErrors {
  streetNumber = 'streetNumber',
  street = 'street',
  city = 'city',
  country = 'country',
  zip = 'zip'
}

export interface IClaimDetails {
  id: string;
  status: StatusType;
  date: string;
  amount: number;
  currency: CurrencyType;
  landlord: string;
  address: string;
  policyId: string;
  damagePlace: DamagedPlaces;
  damageTypes: [DamagedType];
  description: string;
  justificationMessage: string;
  landlordDocuments: [];
  refNumber: number;
  type: ClaimType;
  paymentToken?: string;
}

export interface IManualAddress {
  street: string;
  streetNumber: string;
  zip: string;
  city: string;
  country?: string;
}

export type propertyType = 'COMMERCIAL' | 'RESIDENTIAL';

export interface IDictionaryName {
  name: string;
  values: object;
}

export interface IDictionaryObject {
  name: string;
  value: object;
}

export interface INewClaim {
  policyId: string;
  date: string;
  type: ClaimType;
  amount: number;
  currency: string;
  tenantStillOccupiesProperty: boolean;
  description: string;
  damagePlace: DamagedPlaces;
  damageTypes: DamagedType[];
}

export interface IExplainClaimData {
  claimId: string;
  justificationMessage: string;
}

export interface IPagination {
  page: number;
  pageSize: number;
}

export type sortType = 'updatedDesc' | 'updatedAsc' | 'dateDesc' | 'dateAsc' | 'amountDesc' | 'amountAsc'
  | 'createdAtDesc' | 'createdAtAsc';

export enum sortList {
  updatedDesc = 'updatedDesc',
  updatedAsc = 'updatedAsc',
  dateDesc = 'dateDesc',
  dateAsc = 'dateAsc',
  amountDesc = 'amountDesc',
  amountAsc = 'amountAsc',
  createdAtDesc = 'createdAtDesc',
  createdAtAsc = 'createdAtAsc',
}

export enum Language {
  DE = 'de',
  EN = 'en'
}

export enum Country {
  DE = 'de',
  EN = 'en',
  AT = 'at',
  NL = 'nl',
}

export type LanguageCodeType = ValueOf<Language>;

export enum LanguageNameEnum {
  de = 'Deutsch',
  en = 'English'
}

export type LanguageNameType = ValueOf<LanguageNameEnum>;

export interface ILanguage {
  name: LanguageNameType;
  code: LanguageCodeType;
}

export interface ISuggestionsPlaces {
  session: string;
  suggestions: IPlace[];
}

export enum uniformLoadingEnum {
  suggestionsLoad = 'suggestionsLoad',
  checkSuggestion = 'checkSuggestion'
}

export interface IPlace {
  placeId: string;
  title: string;
  highlightedTitle: string;
}

export type cancellationReasonType = 'MOVING_OUT' | 'NOT_INTERESTED_IN_POLICY' | 'OTHER';

export enum CancellationReasonEnum {
  MOVING_OUT = 'MOVING_OUT',
  NOT_INTERESTED_IN_POLICY = 'NOT_INTERESTED_IN_POLICY',
  OTHER = 'OTHER',
  SWITCHED_CASH_DEPOSIT = 'SWITCHED_CASH_DEPOSIT',
}

export interface IPaginationOptions {
  page: number;
  itemsPerPage: number;
}

export interface IPerkItem {
  title: string;
  text: string;
  icon: VueConstructor;
  link: string;
}

export type helpSourceType = helpSourceEnum;

export enum helpSourceEnum {
  MOBILE = 'MOBILE',
  TENANT_APP = 'TENANT_APP',
  LANDLORD_APP = 'LANDLORD_APP'
}
