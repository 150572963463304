import {
  InvoiceStatusEnum,
  PolicyStatusEnum,
  ClaimStatusEnum,
  StatusType
} from '@/interfaces';

interface IStatusDictionaryValue {
  color: 'primary' | 'success' | 'warning' | 'error';
  text: string;
}

export const StatusDictionary: Record<StatusType, IStatusDictionaryValue> = {
  // Invoice
  [InvoiceStatusEnum.PROGRESS]: {
    color: 'primary',
    text: 'table.status_name.in_progress'
  },
  [InvoiceStatusEnum.SUCCEEDED]: {
    color: 'success',
    text: 'table.status_name.succeeded'
  },
  [InvoiceStatusEnum.CANCELED]: {
    color: 'error',
    text: 'table.status_name.canceled'
  },
  [InvoiceStatusEnum.FAILED]: {
    color: 'error',
    text: 'table.status_name.failed'
  },

  // Policy
  [PolicyStatusEnum.NEW]: {
    color: 'primary',
    text: 'table.status_name.new'
  },
  [PolicyStatusEnum.ACTIVE]: {
    color: 'success',
    text: 'table.status_name.active'
  },
  [PolicyStatusEnum.CANCELLATION]: {
    color: 'warning',
    text: 'table.status_name.cancellation'
  },
  [PolicyStatusEnum.CANCELED]: {
    color: 'error',
    text: 'table.status_name.canceled'
  },

  // Claim
  [ClaimStatusEnum.NEW]: {
    color: 'primary',
    text: 'table.status_name.new'
  },
  [ClaimStatusEnum.IN_PROGRESS]: {
    color: 'warning',
    text: 'table.status_name.in_progress'
  },
  [ClaimStatusEnum.INVOICING]: {
    color: 'warning',
    text: 'table.status_name.invoicing'
  },
  [ClaimStatusEnum.OVERDUE]: {
    color: 'error',
    text: 'table.status_name.overdue'
  },
  [ClaimStatusEnum.CANCELED]: {
    color: 'error',
    text: 'table.status_name.canceled'
  },
  [ClaimStatusEnum.ACCEPTED]: {
    color: 'success',
    text: 'table.status_name.accepted'
  },
  [ClaimStatusEnum.RESOLVED]: {
    color: 'success',
    text: 'table.status_name.resolved'
  },
  [ClaimStatusEnum.UNPAID]: {
    color: 'error',
    text: 'table.status_name.unpaid'
  },
};

export const getValueByStatus = (status: StatusType) => {
  return status in StatusDictionary
    ? StatusDictionary[status]
    : { color: 'default', text: status};
};
