




















import {Component, Prop, Vue} from 'vue-property-decorator';
import {getValueByStatus} from '@/dictionaries/status.dictionary';
import {StatusType} from '@/interfaces';

@Component
export default class SectionText extends Vue {
  private getValueByStatus = getValueByStatus;

  @Prop({ type: String, required: false })
  private title!: string;

  @Prop({ type: [String, Number], required: false, default: '-' })
  private desc!: string | number;

  @Prop({ type: Boolean, required: false, default: false })
  private hr!: boolean;

  @Prop({ type: String, required: false })
  private status!: StatusType;
}
