import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {
  IResponsePageableArray,
  IRemoveFile,
  IUploadedFileData,
  IPolicyFullItem,
  cancellationReasonType,
} from '@/interfaces';

export interface IBankCard {
  cardNumber: string;
  type: string;
}
export interface ICancelAnswer {
  code: number;
  message: string;
}

export interface IPhoneNumber {
  number: string;
  dialCode: string;
}

export interface IPolicyCancellationInfo {
  data: IPolicyCancellationInitiate;
  id: string;
}

export interface IPolicyCancellationInitiate {
  reason: cancellationReasonType | null;
  address: IAddress;
  otherReason?: string;
}

export interface IAddress {
  address: IAddressManual;
  fullAddress: string | null;
  suggestionAddress: ISuggestionAddress | null;
}

export interface IAddressManual {
  street: string | null;
  streetNumber: string | null;
  zip: string | null;
  city: string | null;
  country?: string | null;
}

export interface ISuggestionAddress {
  placeId: string | null;
  title: string | null;
  highlightedTitle: string | null;
}

export interface ISuggestionCheck {
  suggestion: ISuggestionAddress;
  session: string;
  streetNumber?: string;
}

export interface ISuggestionAddressChecked {
  suggestion: ISuggestionAddress;
  streetNumber: string | null;
  zip: string | null;
}

export interface IPolicyApiService {
  getPolicies: () => Promise<IResponsePageableArray>;
  uploadFile: (formData: FormData) => Promise<IUploadedFileData>;
  removeFile: (data: IRemoveFile) => Promise<IRemoveFile>;
  getPolicyInfo: (id: string) => Promise<IPolicyFullItem>;
  uploadPolicyFile: (formData: FormData) => Promise<IUploadedFileData>;
  cancelPolicy: (data: IPolicyCancellationInfo) => Promise<ICancelAnswer>;
  getPolicyInvoices: (id: string) => Promise<IResponsePageableArray>;
}

const getPolicies = (): Promise<IResponsePageableArray> => api
  .get('/policy')
  .then((response: AxiosResponse<IResponsePageableArray>) => response.data)
  .catch((error) => Promise.reject(error));

const uploadFile = (formData: FormData): Promise<IUploadedFileData> => api
  .post(`policy/${formData.get('id')}/document?origin=${formData.get('origin')}`, formData)
  .then((response: AxiosResponse<IUploadedFileData>) => response.data)
  .catch((error) => Promise.reject(error));

const removeFile = (data: IRemoveFile): Promise<IRemoveFile> => api
  .delete(`policy/${data.id}/document/${data.documentId}`)
  .then((response: AxiosResponse<IRemoveFile>) => response.data)
  .catch((error) => Promise.reject(error));

const getPolicyInfo = (id: string): Promise<IPolicyFullItem> => api
  .get(`policy/${id}`)
  .then((response: AxiosResponse<IPolicyFullItem>) => response.data)
  .catch((error) => Promise.reject(error));

const uploadPolicyFile = (formData: FormData): Promise<IUploadedFileData> => api
  .post(`policy/${formData.get('id')}/document?origin=${formData.get('origin')}&type=${formData.get('type')}`, formData)
  .then((response: AxiosResponse<IUploadedFileData>) => response.data)
  .catch((error) => Promise.reject(error));

const cancelPolicy = (data: IPolicyCancellationInfo): Promise<ICancelAnswer> => api
  .post(`policy/${data.id}/cancellation-initiate`, data.data)
  .then((response: AxiosResponse<ICancelAnswer>) => response.data)
  .catch((error) => Promise.reject(error));

const getPolicyInvoices = (data): Promise<IResponsePageableArray> => api
  .get(`policy/${data.id}/invoices?sortField=${data.sortField ?? 'createdAt'}&sortDirection=${data.sortDirection ?? 'desc'}&page=${data.page ?? 1}&pageSize=${data.pageSize ?? 5}`)
  .then((response: AxiosResponse<IResponsePageableArray>) => response.data)
  .catch((error) => Promise.reject(error));

const PolicyApiService: IPolicyApiService = {
  getPolicies,
  uploadFile,
  removeFile,
  getPolicyInfo,
  uploadPolicyFile,
  cancelPolicy,
  getPolicyInvoices
};

export default PolicyApiService;
