import {ActionContext, ActionTree} from 'vuex';
import { RootState } from '@/store';
import {IAuthCredentials, IUserInfo} from '@/interfaces';
import AuthorizationService, {IAuthResponse} from '@/services/api/authorization.service';
import TokenService from '@/services/data/token.service';
import UserService, {IConfirmResetPassword, IPasswordChangeInfo} from '@/services/api/user.service';
import {getField, updateField} from 'vuex-map-fields';
import router from '@/router';
import eventsSse from '@/services/sse/events.sse';
import {getCountry, getLang} from '@/plugins/vue-i18n';

export interface IStripeInfo {
  clientSecret: string;
  id: string;
}

export interface IUserState {
  user: {} | IUserInfo;
  token: null | string;
  confirmResetPasswordToken: null | string;
  stripeInfo: null | IStripeInfo;
  isAuthenticated: boolean;
  signInRedirectUrl: string;
  stream: () => void | null;
}

const initialState = (): IUserState => ({
  user: {
    phoneNumber: {},
    bankCard: {}
  },
  token: null,
  confirmResetPasswordToken: null,
  stripeInfo: null,
  isAuthenticated: false,
  signInRedirectUrl: '',
  stream: () => null,
});
const state = initialState();

const getters = {
  getUserFields: (state: IUserState) => getField(state),
  getUserInfo: (state: IUserState) => state.user,
  getConfirmResetPasswordToken: (state: IUserState) => state.confirmResetPasswordToken,
  getStripeKey: (state: IUserState) => state.stripeInfo?.clientSecret,
  getStripeId: (state: IUserState) => state.stripeInfo?.id,
  language: () => getLang(),
  country: () => getCountry(),
  getSignInRedirectUrl: (state: IUserState) => state.signInRedirectUrl,
  getStream: (state: IUserState) => state.stream,
};

const mutations = {
  updateUserField(state: IUserState, field: string) {
    return updateField(state, field);
  },
  ['SET_USER'](state: IUserState, payload) {
    state.user = {
      ...payload,
      phoneNumber: payload.phoneNumber ? payload.phoneNumber : {dialCode: '', number: null}};
  },
  ['SET_TOKEN'](state: IUserState, payload: null | string) {
    state.token = payload;
  },
  ['SET_CONFIRM_RESET_PASSWORD_TOKEN'](state: IUserState, payload: null | string) {
    state.confirmResetPasswordToken = payload;
  },
  ['SET_IS_AUTHENTICATED'](state: IUserState, payload: boolean) {
    state.isAuthenticated = payload;
  },
  ['SET_STRIPE_INFO'](state: IUserState, payload: IStripeInfo) {
    state.stripeInfo = payload;
  },
  ['SET_SIGN_IN_REDIRECT_URL'](state: IUserState, payload: string) {
    state.signInRedirectUrl = payload;
  },
  ['SET_STREAM'](state: IUserState, payload) {
    state.stream = payload;
  },
};

const actions: ActionTree<IUserState, RootState> = {
  setLanguage: (_, language) => {
    const name = router.currentRoute.name;
    if (name) {
      router.push({params: {lang: (language)}});
    }
  },
  logIn: ({commit, dispatch}: ActionContext<IUserState, RootState>, credentials: IAuthCredentials) => {
    commit('SET_LOADING', {logIn: true});
    dispatch('setToken', null);
    return AuthorizationService.logIn(credentials)
      .then(({token}: IAuthResponse) => {
        return dispatch('setToken', {
            value: token,
            rememberMe: credentials.rememberMe
          });
        })
      .finally(() => commit('SET_LOADING', {logIn: false}));
  },
  logOut: ({commit}) => {
    TokenService.resetToken();
    commit('SET_IS_AUTHENTICATED', false);
    router.push({name: 'signIn'});
  },
  getUser: ({commit, getters, dispatch}) => {
    commit('SET_LOADING', {user: true});
    commit('SET_STREAM', eventsSse.eventsStream());
    return UserService.getUserInfo()
      .then((user) => {
        commit('SET_USER', user);
        commit('SET_UNIFORM_LOADING', {skeletonLoader: true});

        dispatch('getNotifications');
        dispatch('streamNotifications');

        if (!getters.getUserFields('isAuthenticated')) {
          commit('SET_IS_AUTHENTICATED', true);
        }
        return user;
      })
      .finally(() => commit('SET_LOADING', {user: false}));
  },
  getProfilePayment: ({commit}, id: string) => {
    commit('SET_UNIFORM_LOADING', {profilePayment: true});
    return UserService.getUserInfoPayment(id)
      .then((user) => {
        commit('SET_USER', user);
        return user;
      })
      .finally(() => commit('SET_UNIFORM_LOADING', {profilePayment: false}));
  },
  setToken: ({commit}, tokenInfo: {value: string; rememberMe: boolean} | null) => {
    if (tokenInfo) {
      commit('SET_TOKEN', tokenInfo.value);
      TokenService.setToken(tokenInfo);
    } else {
      commit('SET_TOKEN', null);
      TokenService.resetToken();
      commit('SET_IS_AUTHENTICATED', false);
    }
  },
  authenticate: ({dispatch}) => {
    const token = TokenService.getToken() || null;
    if (!token) { return; }
    return dispatch('getUser');
  },
  setNewPassword: ({commit}, payload: IPasswordChangeInfo) => {
    commit('SET_LOADING', {password: true});
    return UserService.setNewPassword(payload)
      .finally(() => commit('SET_LOADING', {password: false}));
  },
  updateProfile: ({commit}, userInfo: IUserInfo) => {
    commit('SET_LOADING', {profile: true});
    const payload: IUserInfo = {
      ...userInfo,
      phoneNumber: userInfo.phoneNumber?.number ? userInfo.phoneNumber : null
    };
    return UserService.setUserInfo(payload)
      .then((user) => commit('SET_USER', user))
      .finally(() => commit('SET_LOADING', {profile: false}));
  },
  resetPassword: ({commit}, payload) => {
    commit('SET_LOADING', {password: true});
    return UserService.resetPassword(payload)
      .then(() => Promise.resolve())
      .finally(() => commit('SET_LOADING', {password: false}));
  },
  confirmResetPassword: ({commit}, payload: IConfirmResetPassword) => {
    commit('SET_LOADING', {password: true});
    return UserService.confirmResetPassword(payload)
      .finally(() => commit('SET_LOADING', {password: false}));
  },
  getStripeKey: ({commit}) => {
    commit('SET_LOADING', {stripe: true});
    return UserService.getStripeKey()
      .then((answer) => commit('SET_STRIPE_INFO', answer))
      .finally(() => commit('SET_LOADING', {stripe: false}));
  },
  sendHelp: ({commit}, helpData) => {
    commit('SET_LOADING', {help: true});
    return UserService.help(helpData)
      .finally(() => commit('SET_LOADING', {help: false}));
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
