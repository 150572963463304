














import {Component, Vue, Prop} from 'vue-property-decorator';
import {scrollTo} from '@/utilities/scroll';

@Component
export default class HeaderMenuBtn extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  private visible!: false;

  private activeMenu() {
    this.$root.$emit('isHeaderDrawer', true);
    scrollTo('.header');
  }
}
