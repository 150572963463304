import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {ISuggestionsPlaces} from '@/interfaces';
import {ISearchRequest} from '@/store/modules/search.store';
import {ISuggestionAddressChecked, ISuggestionCheck} from '@/services/api/policy.service';

export interface ISearchService {
  getNewAddresses: (request: ISearchRequest) => Promise<ISuggestionsPlaces>;
  suggestionCheck: (data: ISuggestionCheck) => Promise<ISuggestionAddressChecked>;
}

const getNewAddresses = (params: ISearchRequest): Promise<ISuggestionsPlaces> => api
  .get('/suggestion', {params})
  .then((response: AxiosResponse<ISuggestionsPlaces>) => response.data)
  .catch((error) => Promise.reject(error));

const suggestionCheck = (data: ISuggestionCheck) => api
  .post('/suggestion/check', data)
  .then((response: AxiosResponse<ISuggestionAddressChecked>) => response.data)
  .catch((error) => Promise.reject(error));

const SearchService: ISearchService = {
  getNewAddresses,
  suggestionCheck,
};

export default SearchService;
